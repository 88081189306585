import React from 'react';
import AppointmentOptions from '../QRTypes/Appointment/AppointmentOptions';
import AssetOptions from '../QRTypes/Asset/AssetOptions';
import LocationOptions from '../QRTypes/Location/LocationOptions';
import RedirectLinkOptions from '../QRTypes/RedirectLink/RedirectLinkOptions';
import MenuOptions from '../QRTypes/Menu/MenuOptions';
import MicrositeOptions from '../QRTypes/Microsite/MicrositeOptions';
import PDFOptions from '../QRTypes/PDF/PDFOptions';
import TrackerOptions from '../QRTypes/Tracker/TrackerOptions';
import SurveyOptions from '../QRTypes/Survey/SurveyOptions';
import BitSignalOptions from '../QRTypes/BitSignal/BitSignalOptions';

export default function Options(props) {
    const { qrType, qr_data, dataDispatch, id, qrFiles, setQrFiles, setDeleteFiles, filesToDisplay, setFilesToDisplay } = props;

    switch (qrType) {
        case 'appointment':
            return <AppointmentOptions qr_data={qr_data} dataDispatch={dataDispatch} />;
        case 'assets':
        case 'container-label':
        case 'liquid-label':
        case 'inventory':
            return <AssetOptions qr_data={qr_data} dataDispatch={dataDispatch} qrFiles={qrFiles} setQrFiles={setQrFiles} setDeleteFiles={setDeleteFiles} filesToDisplay={filesToDisplay} setFilesToDisplay={setFilesToDisplay} />;
        case 'location':
            return <LocationOptions qr_data={qr_data} dataDispatch={dataDispatch} />;
        case 'link':
        case 'youtube':
        case 'tiktok':
        case 'linkedin':
        case 'google-review':
        case 'review-link':
            return <RedirectLinkOptions qrType={qrType} qr_data={qr_data} dataDispatch={dataDispatch} />;
        case 'menu':
            return <MenuOptions qr_data={qr_data} dataDispatch={dataDispatch} id={id} qrFiles={qrFiles} setQrFiles={setQrFiles} setDeleteFiles={setDeleteFiles} />;
        case 'microsite':
        case 'landing-page':
            return <MicrositeOptions qr_data={qr_data} dataDispatch={dataDispatch} />;
        case 'pdf':
        case 'brochure':
        case 'user-manual':
            return <PDFOptions qrType={qrType} qr_data={qr_data} dataDispatch={dataDispatch} id={id} qrFiles={qrFiles} setQrFiles={setQrFiles} setDeleteFiles={setDeleteFiles} />;
        case 'tracker':
        case 'workout-log':
        case 'service-log':
        case 'vehicle-log':
        case 'maintenance-tracker':
            return <TrackerOptions qrType={qrType} qr_data={qr_data} dataDispatch={dataDispatch} />;
        case 'survey':
        case 'review':
        // case 'bug-report':
            return <SurveyOptions qrType={qrType} qr_data={qr_data} dataDispatch={dataDispatch} />;
        case 'bitsignal':
        case 'dbc':
            return <BitSignalOptions qr_data={qr_data} dataDispatch={dataDispatch} id={id}/>
        default:
            // what should default be?
            return <></>;
    };
};