import React, { useEffect, useState } from 'react';
import CodesImg from '../../components/Images/qr-background.png';
import { useAuth } from '../../utils/Auth';
import { Navigate, Link } from 'react-router-dom';
import { Stripe } from '../../components/Stripe';
import styles from './Upgrade.module.css';

export default function Upgrade() {
    const { userObj, currentUser, orgObj } = useAuth();
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const selectedPlan = urlParams.get('tier');

    const [ tier, setTier ] = useState(null);
    const [ confirm, setConfirm ] = useState(false);
    const [ disabled, setDisabled ] = useState(true);
    const [ showSuccess, setShowSuccess ] = useState(false);
    // -------------------

    useEffect(() => {
        setDisabled((userObj?.stripe_id && orgObj?.plan?.plan_id === tier) || (!userObj?.sub_id && tier === 0));
    },[tier, orgObj?.plan?.plan_id, userObj?.stripe_id, userObj?.sub_id]);

    useEffect(() => {
        const free = document.getElementById('upgrade-free');
        const basic = document.getElementById('upgrade-basic');
        // const basicplus = document.getElementById('upgrade-basic+');
        const pro = document.getElementById('upgrade-pro');
        const proplus = document.getElementById('upgrade-pro+');
        const enterprise = document.getElementById('upgrade-enterprise');

        if (selectedPlan) {
            if (selectedPlan === 'free') {
                setTier(0);
                free?.classList.add(styles.current);
                basic?.classList.remove(styles.current);
                // basicplus?.classList.remove(styles.current);
                pro?.classList.remove(styles.current);
                proplus?.classList.remove(styles.current);
                enterprise?.classList.remove(styles.current);
            } else if (selectedPlan === 'basic') {
                setTier(1);
                free?.classList.remove(styles.current);
                basic?.classList.add(styles.current);
                // basicplus?.classList.remove(styles.current);
                pro?.classList.remove(styles.current);
                proplus?.classList.remove(styles.current);
                enterprise?.classList.remove(styles.current);
            } else if (selectedPlan === 'basic+') {
                setTier(2);
                free?.classList.remove(styles.current);
                basic?.classList.remove(styles.current);
                // basicplus?.classList.add(styles.current);
                pro?.classList.remove(styles.current);
                proplus?.classList.remove(styles.current);
                enterprise?.classList.remove(styles.current);
            } else if (selectedPlan === 'pro') {
                setTier(3);
                free?.classList.remove(styles.current);
                basic?.classList.remove(styles.current);
                // basicplus?.classList.remove(styles.current);
                pro?.classList.add(styles.current);
                proplus?.classList.remove(styles.current);
                enterprise?.classList.remove(styles.current);
            } else if (selectedPlan === 'pro+') {
                setTier(4);
                free?.classList.remove(styles.current);
                basic?.classList.remove(styles.current);
                // basicplus?.classList.remove(styles.current);
                pro?.classList.remove(styles.current);
                proplus?.classList.add(styles.current);
                enterprise?.classList.remove(styles.current);
            } else if (selectedPlan === 'enterprise') {
                setTier(5);
                free?.classList.remove(styles.current);
                basic?.classList.remove(styles.current);
                // basicplus?.classList.remove(styles.current);
                pro?.classList.remove(styles.current);
                proplus?.classList.remove(styles.current);
                enterprise?.classList.add(styles.current);
            };
        } else if (orgObj?.plan) {
            setTier(orgObj?.plan?.plan_id);
            if (orgObj?.plan?.plan_id === 0) {
                free?.classList.add(styles.current);
                basic?.classList.remove(styles.current);
                // basicplus?.classList.remove(styles.current);
                pro?.classList.remove(styles.current);
                proplus?.classList.remove(styles.current);
                enterprise?.classList.remove(styles.current);
            } else if (orgObj?.plan?.plan_id === 1) {
                free?.classList.remove(styles.current);
                basic?.classList.add(styles.current);
                // basicplus?.classList.remove(styles.current);
                pro?.classList.remove(styles.current);
                proplus?.classList.remove(styles.current);
                enterprise?.classList.remove(styles.current);
            } else if (orgObj?.plan?.plan_id === 2) {
                free?.classList.remove(styles.current);
                basic?.classList.remove(styles.current);
                // basicplus?.classList.add(styles.current);
                pro?.classList.remove(styles.current);
                proplus?.classList.remove(styles.current);
                enterprise?.classList.remove(styles.current);
            } else if (orgObj?.plan?.plan_id === 3) {
                free?.classList.remove(styles.current);
                basic?.classList.remove(styles.current);
                // basicplus?.classList.remove(styles.current);
                pro?.classList.add(styles.current);
                proplus?.classList.remove(styles.current);
                enterprise?.classList.remove(styles.current);
            } else if (orgObj?.plan?.plan_id === 4) {
                free?.classList.remove(styles.current);
                basic?.classList.remove(styles.current);
                // basicplus?.classList.remove(styles.current);
                pro?.classList.remove(styles.current);
                proplus?.classList.add(styles.current);
                enterprise?.classList.remove(styles.current);
            } else if (orgObj?.plan?.plan_id === 5) {
                free?.classList.remove(styles.current);
                basic?.classList.remove(styles.current);
                // basicplus?.classList.remove(styles.current);
                pro?.classList.remove(styles.current);
                proplus?.classList.remove(styles.current);
                enterprise?.classList.add(styles.current);
            };
        };
    },[selectedPlan, orgObj?.plan]);

    const handleTierChange = (e) => {
        const id = e.target.id;
        const free = document.getElementById('upgrade-free');
        const basic = document.getElementById('upgrade-basic');
        // const basicplus = document.getElementById('upgrade-basic+');
        const pro = document.getElementById('upgrade-pro');
        const proplus = document.getElementById('upgrade-pro+');
        const enterprise = document.getElementById('upgrade-enterprise');

        if (id === 'upgrade-free') {
            setTier(0);
            free?.classList.add(styles.current);
            basic?.classList.remove(styles.current);
            // basicplus?.classList.remove(styles.current);
            pro?.classList.remove(styles.current);
            proplus?.classList.remove(styles.current);
            enterprise?.classList.remove(styles.current);
        } else if (id === 'upgrade-basic') {
            setTier(1);
            free?.classList.remove(styles.current);
            basic?.classList.add(styles.current);
            // basicplus?.classList.remove(styles.current);
            pro?.classList.remove(styles.current);
            proplus?.classList.remove(styles.current);
            enterprise?.classList.remove(styles.current);
        } else if (id === 'upgrade-basic+') {
            setTier(2);
            free?.classList.remove(styles.current);
            basic?.classList.remove(styles.current);
            // basicplus?.classList.add(styles.current);
            pro?.classList.remove(styles.current);
            proplus?.classList.remove(styles.current);
            enterprise?.classList.remove(styles.current);
        } else if (id === 'upgrade-pro') {
            setTier(3);
            free?.classList.remove(styles.current);
            basic?.classList.remove(styles.current);
            // basicplus?.classList.remove(styles.current);
            pro?.classList.add(styles.current);
            proplus?.classList.remove(styles.current);
            enterprise?.classList.remove(styles.current);
        } else if (id === 'upgrade-pro+') {
            setTier(4);
            free?.classList.remove(styles.current);
            basic?.classList.remove(styles.current);
            // basicplus?.classList.remove(styles.current);
            pro?.classList.remove(styles.current);
            proplus?.classList.add(styles.current);
            enterprise?.classList.remove(styles.current);
        } else if (id === 'upgrade-enterprise') {
            setTier(5);
            free?.classList.remove(styles.current);
            basic?.classList.remove(styles.current);
            // basicplus?.classList.remove(styles.current);
            pro?.classList.remove(styles.current);
            proplus?.classList.remove(styles.current);
            enterprise?.classList.add(styles.current);
        };
    };

    if (userObj && orgObj && currentUser) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                        <div className={styles.card}>
                            <div className={styles.column1}>
                                <div className={styles.contentTitle}>
                                    <div className={styles.contentTitleInner}>Content</div>
                                </div>
                                <div className={styles.content}>Dynamic QRs</div>
                                <div className={styles.content}># of Users</div>
                                <div className={styles.content}>Categories</div>
                                <div className={styles.content}>Support</div>
                                <div className={styles.content}>Analytics</div>
                                <div className={styles.content}>Price</div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.title}>
                                    <div className={`${styles.tiers} ${styles.current}`} id='upgrade-free' onClick={handleTierChange}>Free</div>
                                    <div className={styles.tiers} id='upgrade-basic' onClick={handleTierChange}>Basic</div>
                                    {/* <div className={styles.tiers} id='upgrade-basic+' onClick={handleTierChange}>Basic+</div> */}
                                    <div className={styles.tiers} id='upgrade-pro' onClick={handleTierChange}>Pro</div>
                                    <div className={styles.tiers} id='upgrade-pro+' onClick={handleTierChange}>Pro+</div>
                                    <div className={styles.tiers} id='upgrade-enterprise' onClick={handleTierChange}>Enterprise</div>
                                </div>
                                <div className={styles.titleCurrent}>
                                    <div className={styles.titleCurrentTiers}>{orgObj?.plan?.plan_id === 0 ? '(Current)' : <></>}</div>
                                    <div className={styles.titleCurrentTiers}>{orgObj?.plan?.plan_id === 1 ? '(Current)' : <></>}</div>
                                    {/* <div className={styles.titleCurrentTiers}>{orgObj?.plan?.plan_id === 2 ? '(Current)' : <></>}</div> */}
                                    <div className={styles.titleCurrentTiers}>{orgObj?.plan?.plan_id === 3 ? '(Current)' : <></>}</div>
                                    <div className={styles.titleCurrentTiers}>{orgObj?.plan?.plan_id === 4 ? '(Current)' : <></>}</div>
                                    <div className={styles.titleCurrentTiers}>{orgObj?.plan?.plan_id === 5 ? '(Current)' : <></>}</div>
                                </div>
                                <div className={styles.middle}>
                                    <div className={styles.middle0}>
                                        <div>1</div>
                                        <div>1</div>
                                        <div>1</div>
                                        <div style={{fontSize: '1.6rem'}}><i className='fas fa-ban' /></div>
                                        <div style={{fontSize: '1.6rem'}}><i className='fas fa-ban'/></div>
                                        <div>Free</div>
                                    </div>
                                    <div className={styles.middle1}>
                                        <div>25</div>
                                        <div>1</div>
                                        <div>3</div>
                                        <div>Basic</div>
                                        <div>Basic</div>
                                        <div>$14.95/month</div>
                                    </div>
                                    {/* <div className={styles.middle2}>
                                        <div>25</div>
                                        <div>1</div>
                                        <div>3</div>
                                        <div>Basic</div>
                                        <div>Basic</div>
                                        <div>$9.95/month</div>
                                    </div> */}
                                    <div className={styles.middle3}>
                                        <div>50</div>
                                        <div>3</div>
                                        <div>10</div>
                                        <div>Advanced</div>
                                        <div>Advanced</div>
                                        <div>$19.95/month</div>
                                    </div>
                                    <div className={styles.middle4}>
                                        <div>200</div>
                                        <div>5</div>
                                        <div>25</div>
                                        <div>Advanced</div>
                                        <div>Advanced</div>
                                        <div>$89.95/month</div>
                                    </div>
                                    <div className={styles.middle5}>
                                        <div>Custom</div>
                                        <div>Custom</div>
                                        <div>Custom</div>
                                        <div>Advanced</div>
                                        <div>Advanced</div>
                                        <div>Custom</div>
                                    </div>
                                </div>
                                <div className={styles.middleMobile}>
                                    {tier === 0 ?
                                        <div className={styles.middle0}>
                                            <div>1</div>
                                            <div>1</div>
                                            <div>1</div>
                                            <div style={{fontSize: '1.6rem'}}><i className='fas fa-ban' /></div>
                                            <div style={{fontSize: '1.6rem'}}><i className='fas fa-ban'/></div>
                                            <div>Free</div>
                                        </div>
                                    : tier === 1 ?
                                        <div className={styles.middle1}>
                                            <div>25</div>
                                            <div>1</div>
                                            <div>3</div>
                                            <div>Basic</div>
                                            <div>Basic</div>
                                            <div>$14.95/month</div>
                                        </div>
                                    // : tier === 2 ?
                                    //     <div className={styles.middle2}>
                                    //         <div>25</div>
                                    //         <div>1</div>
                                    //         <div>3</div>
                                    //         <div>Basic</div>
                                    //         <div>Basic</div>
                                    //         <div>$9.95/month</div>
                                    //     </div>
                                    : tier === 3 ?
                                        <div className={styles.middle3}>
                                            <div>50</div>
                                            <div>3</div>
                                            <div>10</div>
                                            <div>Advanced</div>
                                            <div>Advanced</div>
                                            <div>$19.95/month</div>
                                        </div>
                                    : tier === 4 ?
                                        <div className={styles.middle4}>
                                            <div>200</div>
                                            <div>5</div>
                                            <div>25</div>
                                            <div>Advanced</div>
                                            <div>Advanced</div>
                                            <div>$89.95/month</div>
                                        </div>
                                    : tier === 5 ?
                                        <div className={styles.middle5}>
                                            <div>Custom</div>
                                            <div>Custom</div>
                                            <div>Custom</div>
                                            <div>Advanced</div>
                                            <div>Advanced</div>
                                            <div>Custom</div>
                                        </div>
                                    : <></>
                                    }
                                </div>
                                <div className={styles.stripeContainer}>
                                    <Stripe basePlan={tier} userObj={userObj} email={currentUser.email} confirm={confirm} setConfirm={setConfirm} disabled={disabled} setShowSuccess={setShowSuccess} />
                                </div>
                            </div>
                        </div>
                    {confirm && userObj.sub_id && !showSuccess?
                        <p style={{marginTop: '1.5rem'}} className='text-center'>** Upgrading will automatically charge your card on file. **</p>
                    : showSuccess ? 
                        <p style={{marginTop: '1.5rem'}} className='text-center'>** Upgrade successful! Check email to see your invoice. **</p>
                    :
                        <p style={{marginTop: '1.5rem'}} className='text-center'>** Please allow a couple of hours for your upgrade to take effect. **</p>
                    }
                    <div className={styles.footer} style={{backgroundImage: `url(${CodesImg})`}} />
                    <div className={styles.collectionRedirect} >
                        <Link to='/' className={styles.collectionRedirectBtn} >Back to Dashboard</Link>
                    </div>
                </div>
            </div>
        );
    } else if (currentUser && !orgObj) {
        return (<></>);
    } else {
        return (<Navigate to={`/login${query}`} />);
    };
};
