import { useMemo } from "react";

export const handleTypeHeaderIcon = (type) => {
    const data = { header: '', icon: '' };
    switch (type) {
        case 'pdf':
            data.header = 'PDF';
            data.icon = 'fas fa-file-pdf';
            break;
        case 'brochure':
            data.header = 'Brochure';
            data.icon = 'fa-solid fa-file';
            break;
        case 'menu':
            data.header = 'Menu';
            data.icon = 'fas fa-fork-knife';
            break;
        case 'location':
            data.header = 'Location';
            data.icon = 'fas fa-map-location';
            break;
        case 'bitsignal':
            data.header = 'BitSignal';
            data.icon = 'fak fa-bitsignal';
            break;
        case 'liquid-label':
            data.header = 'Liquid Label';
            data.icon = 'fa-solid fa-tag';
            break;
        case 'assets':
            data.header = 'Assets';
            data.icon = 'fas fa-shelves';
            break;
        case 'inventory':
            data.header = 'Inventory';
            data.icon = 'fa-solid fa-warehouse-full';
            break;
        case 'tracker':
            data.header = 'Tracker';
            data.icon = 'fas fa-clipboard-list-check';
            break;
        case 'workout-log':
            data.header = 'Workout Log';
            data.icon = 'fa-solid fa-person-walking';
            break;
        case 'service-log':
            data.header = 'Service Log';
            data.icon = 'fa-regular fa-table-list';
            break;
        case 'survey':
            data.header = 'Liquid Feedback';
            data.icon = 'fa-regular fa-square-poll-vertical';
            break;
        case 'review':
            data.header = 'Review Survey';
            data.icon = 'fa-solid fa-star-sharp-half-stroke';
            break;
        // case 'bug-report':
        //     data.header = 'Bug Report';
        //     data.icon = 'fa-solid fa-bug';
        //     break;
        case 'review-link':
            data.header = 'Review Link';
            data.icon = 'fa-regular fa-list-ul';
            break;
        case 'quiz':
            data.header = 'Quiz';
            data.icon = 'fa-regular fa-school';
            break;
        case 'appointment':
            data.header = 'Appointment';
            data.icon = 'fas fa-calendar-days';
            break;
        case 'calendly':
            data.header = 'Calendly';
            data.icon = 'fas fa-calendar-days';
            break;
        case 'google-calendar':
            data.header = 'Google Calendar';
            data.icon = 'fa-brands fa-google';
            break;
        case 'google-review':
            data.header = 'Google Review';
            data.icon = 'fa-brands fa-google';
            break;
        case 'apple-music':
            data.header = 'Apple Music';
            data.icon = 'fa-brands fa-apple';
            break;
        case 'spotify':
            data.header = 'Spotify';
            data.icon = 'fa-solid fa-music';
            break;
        case 'youtube':
            data.header = 'YouTube';
            data.icon = 'fa-brands fa-youtube';
            break;
        case 'tiktok':
            data.header = 'TikTok';
            data.icon = 'fa-brands fa-tiktok';
            break;
        case 'twitch':
            data.header = 'Twitch';
            data.icon = 'fa-brands fa-twitch';
            break;
        case 'facebook':
            data.header = 'Facebook';
            data.icon = 'fa-brands fa-facebook';
            break;
        case 'instagram':
            data.header = 'Instagram';
            data.icon = 'fa-brands fa-instagram';
            break;
        case 'linkedin':
            data.header = 'LinkedIn';
            data.icon = 'fa-brands fa-linkedin';
            break;
        case 'microsite':
            data.header = 'Microsite';
            data.icon = 'fas fa-square-list';
            break;
        case 'landing-page':
            data.header = 'Landing Page';
            data.icon = 'fa-solid fa-browser';
            break;
        case 'link':
            data.header = 'Link';
            data.icon = 'fas fa-link-simple';
            break;
        case 'vehicle-log':
            data.header = 'Vehicle Log';
            data.icon = 'fa-regular fa-car-side';
            break;
        case 'container-label':
            data.header = 'Container Label';
            data.icon = 'fa-regular fa-tag';
            break;
        case 'dbc':
            data.header = 'Digital Business Card';
            data.icon = 'fak fa-bitsignal';
            break;
        case 'maintenance-tracker':
            data.header = 'Maintenance Tracker';
            data.icon = 'fa-regular fa-screwdriver-wrench';
            break;
        case 'user-manual':
            data.header = 'User Manual';
            data.icon = 'fa-regular fa-book';
            break;
        default:
            break;
    };
    return data;
};

export const useTypeHeaderIcon = (type) => useMemo(() => handleTypeHeaderIcon(type), [type]);