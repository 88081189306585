import { customAlphabet } from 'nanoid';

const getIds = (num) => {
    const nanoid = customAlphabet('qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM0123456789', 6);
    const ids = [];

    const handleIds = () => {
        const id = nanoid();
        if (ids.includes(id)) {
            return handleIds();
        };

        return id;
    };
    for (let i = 0; i < num; i++) {
        ids.push(handleIds(0));
    };
    return ids;
};

const serviceLog = () => {
    const ids = getIds(3);
    return [{
        id: 'date',
        name: 'Date',
        type: 'date',
        archived: false,
        options: [],
        required: false
    },{
        id: ids[0],
        name: 'Name',
        type: 'text-box',
        archived: false,
        options: [],
        required: true
    },{
        id: ids[1],
        name: 'Location',
        type: 'text-box',
        archived: false,
        options: [],
        required: true
    },{
        id: ids[2],
        name: 'Notes',
        type: 'text-area',
        archived: false,
        options: [],
        required: false
    }];
};

const workoutLog = () => {
    const ids = getIds(2);
    return [{
        id: 'date',
        name: 'Date',
        type: 'date',
        archived: false,
        options: [],
        required: false
    }, {
        id: ids[0],
        name: 'Areas Focused:',
        type: 'checkbox',
        archived: false,
        options: ['Endurance','Strength','Balance','Flexibility','None'],
        required: true
    }, {
        id: ids[1],
        name: 'Exercises Completed:',
        type: 'checkbox',
        archived: false,
        options: ['Cardio (walking, running, biking, swimming, elipitical)','Upper Body (bench press, overhead, pull-ups, curls, push-ups)','Lower Body (squats, deadlift, calf raises)','Core (crunches, plank)','Arms (bicep curls, wrist curls, tricep extensions)','Classes or Guided Workouts (yoga, zumba, pilates, jazzercise)','None'],
        required: true
    }];
};

const vehicleLog = () => {
    const ids = getIds(2);
    return [{
        id: 'date',
        name: 'Date',
        type: 'date',
        archived: false,
        options: [],
        required: false
    }, {
        id: ids[0],
        name: 'Service Received',
        type: 'checkbox',
        archived: false,
        options: ['Filled Gas','Oil Change','Tire Rotation/Balance','Other'],
        required: true
    }, {
        id: ids[1],
        name: 'Notes from Service',
        type: 'text-area',
        archived: false,
        options: [],
        required: false
    }];
};

const maintenanceTracker = () => {
    const ids = getIds(3);
    return [{
        id: 'date',
        name: 'Date',
        type: 'date',
        archived: false,
        options: [],
        required: false
    }, {
        id: ids[0],
        name: 'Location of Maintenance',
        type: 'text-box',
        archived: false,
        options: [],
        required: true
    }, {
        id: ids[1],
        name: 'Equipment tested/fixed/maintained',
        type: 'text-box',
        archived: false,
        options: [],
        required: true
    },{
        id: ids[2],
        name: 'Next steps if any',
        type: 'text-area',
        archived: false,
        options: [],
        required: false
    },{
        id: ids[3],
        name: 'Notes',
        type: 'text-area',
        archived: false,
        options: [],
        required: false
    }];
};

const bugReport = () => {
    const ids = getIds(3);
    return [{
        id: 'date',
        name: 'Date',
        type: 'date',
        archived: false,
        options: [],
        required: false
    }, {
        id: ids[0],
        name: 'Location or page where the bug occurred',
        type: 'text-box',
        archived: false,
        options: [],
        required: true
    }, {
        id: ids[1],
        name: 'Is this a breaking bug?',
        type: 'radio',
        archived: false,
        options: ['Yes','No','Not Sure'],
        required: true
    },{
        id: ids[2],
        name: 'Describe steps to recreate the bug',
        type: 'text-area',
        archived: false,
        options: [],
        required: false
    }];
};

const review = () => {
    const ids = getIds(2);
    return [{
        id: 'date',
        name: 'Date',
        type: 'date',
        archived: false,
        options: [],
        required: false
    }, {
        archived: false,
        icon: 'stars',
        id: ids[0],
        name: 'Rate your experience 1 to 5, 1 being worst 5 being best',
        options: [],
        required: true,
        type: 'icon-rating'
    }, {
        id: ids[1],
        name: 'What, if anything, would you like to see changed on your next visit?',
        type: 'text-area',
        archived: false,
        options: [],
        required: false
    }];
};

export const trackerDefaults = {
    'bug-report': bugReport(),
    'maintenance-tracker': maintenanceTracker(),
    'review': review(),
    'service-log': serviceLog(),
    'vehicle-log': vehicleLog(),
    'workout-log': workoutLog(),
};