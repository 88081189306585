import React, { useRef, useState, useEffect } from 'react';
import QRCodeStyling from 'pp-qr-code';
import { useInView } from 'react-intersection-observer';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTypeHeaderIcon } from '../../../../utils/hooks/useTypeHeaderIcon';
import styles from './QRCard.module.css';

export default function QRCard(props) {

    const { QR, setParent } = props;

    const qrRef = useRef(null);
    const { ref, inView } = useInView({
        root: null,
        rootMargin: '0px 0px -25px',
        threshold: .25
    });

    const options = QR?.data?.qr_style;
    const [ code, setCode ] = useState(inView ? new QRCodeStyling(options) : null);
    const { icon } = useTypeHeaderIcon(QR?.data?.qr_type);
    const [ confirm, setConfirm ] = useState(false);

    useEffect(() => {
        if (!code && inView && options) {
            setCode(new QRCodeStyling(options));
        } else if (code && inView && options) {
            code.update(options);
            code.append(qrRef.current);
        };
    },[inView, code, options])

    useEffect(() => {
        if (confirm) {
            const timeout = setTimeout(() => {
                setConfirm(false);
            },2000);

            return () => clearTimeout(timeout);
        };
    },[confirm]);

    return (
        <div className={styles.card} id={QR.id} ref={ref}>
            <OverlayTrigger 
                placement="top"
                overlay={
                    <Tooltip className={styles.tip}>
                        <div>Name: <span>{QR.data.nickname}</span></div>
                        <div>Type: <span>{QR.data.qr_type.charAt(0).toUpperCase() + QR.data.qr_type.slice(1)} <i className={icon} /></span></div>
                    </Tooltip>}
            >
                <div className={styles.name}>{QR.data.nickname}</div>
            </OverlayTrigger>
            <div className={styles.qr} ref={qrRef}>{!code ? <i className='fa-duotone fa-spinner fa-spin' style={{fontSize: '2rem'}} /> : <></>}</div>
            <div className={styles.btns}>
                <button className={confirm ? `${styles.selectBtn} ${styles.hide}` : styles.selectBtn} onClick={() => setConfirm(true)}>Select</button>
                <button className={confirm ? `${styles.confirmBtn} ${styles.show}` : styles.confirmBtn} onClick={() => setParent({obj: QR, selected: true})}>Confirm</button>
            </div>
        </div>
    );
};