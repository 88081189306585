import React from 'react';
import Tags from '../../Tags';
import Categories from '../../Categories';
import { useTypeHeaderIcon } from '../../../../utils/hooks/useTypeHeaderIcon';
import styles from './Tracker.module.css';

export default function Tracker(props) {
    // ----- STATE INITIALIZATION -----
    const { qrType, qr_data, dataDispatch, categoryList, setCategoryList, plan, plan_id } = props;
    const { header } = useTypeHeaderIcon(qrType);
    // ----- END STATE INITIALIZATION -----

    return (
        <>
            <div className={styles.inputGroup}>
                <div className={styles.fade} />
                <textarea id='tracker-textarea-label' className={styles.textArea} value={qr_data?.tracker?.description} onChange={e => dataDispatch({type: 'tracker-description', data: e.target.value})} ></textarea>
                <label htmlFor='tracker-textarea-label' className={qr_data?.tracker?.description ? styles.labelFilled : styles.label} style={{marginRight:'0.5rem'}}>Description</label>
            </div>
            <Categories categoryList={categoryList.filter(cat => cat.id !== 'Uncategorized')} setCategoryList={setCategoryList} qr_data={qr_data} dataDispatch={dataDispatch} plan_id={plan_id} plan={plan} />
            <Tags qr_data={qr_data} dataDispatch={dataDispatch} />
        </>
    );
};