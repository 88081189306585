import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import API from '../../API';
import AssetDisplay from './AssetDisplay';
import { useAuth } from '../../utils/Auth';
import styles from './AssetPage.module.css';

const AssetPage = () => {
    
    // ----- STATE INITIALIZATION -----
    const { userObj } = useAuth();

    const [ qrObj, setQrObj ] = useState({});
    const [ asset, setAsset ] = useState({});
    const [ pdfLinks, setPdfLinks ] = useState({});

    const { id } = useParams();
    const navigate = useNavigate();
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------
    useEffect(() => {
        const loginRedirect = () => {
            navigate('/login');
        };
        const { getQR } = API;

        const handleGetQR = async () => {
            await getQR(id).then((res) => {
                if (!res) loginRedirect();
                const { asset } = res;
                
                setAsset(asset);
                setQrObj(res);
            }).catch((err) => {
                loginRedirect();
            });
        };

        if (id) {
            handleGetQR();
        };

        return () => setQrObj({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);


    useEffect(() => {
        if (qrObj) {
            setAsset(qrObj?.asset);
        };
    },[qrObj, setAsset]);

    useEffect(() => {
        const handlePDF = async () => {
            const pdfObj = {};

            for (let i = 0; i < asset?.route?.length; i++) {
                if (asset?.route[i]?.includes('.pdf')) {
                    await fetch(`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_files/${asset?.route[i]}`)
                        .then(async res => pdfObj[asset?.route[i]] = URL.createObjectURL(await res.blob()));
                };
            };

            setPdfLinks(pdfObj);
        };

        if (asset?.route?.length > 0) {
            if (asset?.route?.filter(route => route?.includes('.pdf')).length > 0 && Object.keys(pdfLinks)?.length < 1) {
                handlePDF();
            };
        };
    },[asset, pdfLinks]);

    const handleDisplayReturn = () => {
        if (qrObj?.hasOwnProperty('qr_type')) {
            return <AssetDisplay qrObj={qrObj} asset={asset} userObj={userObj} orgId={userObj?.org_id} pdfLinks={pdfLinks} setPdfLinks={setPdfLinks} setQrObj={setQrObj} />;
        } else {
            return <div >Loading...</div>;
        };
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container} >
                {handleDisplayReturn()}
            </div>
        </div>
    );
};

export default AssetPage;