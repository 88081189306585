import React, { useMemo } from 'react';
import styles from './TypeSelect.module.css';

const TypeSelect = (props) => {
    const { dataDispatch, qrType, setQrType, setStep, plan_id, setLinking } = props;
    const disabledQRs = useMemo(() => plan_id < 1,[plan_id]);
    const defaultTrackerTypes = ['workout-log','service-log','vehicle-log','maintenance-tracker'];
    const defaultSurveyTypes = ['review','bug-report'];

    const handleSelect = (type) => {
        if (qrType !== type) {
            if (defaultTrackerTypes.includes(type)) {
                dataDispatch({ type: 'tracker-default-state', data: type });
            } else if (defaultSurveyTypes.includes(type)) {
                dataDispatch({ type: 'survey-default-state', data: type });
            };
            setQrType(type);
        };
        setStep(5);
    };

    return (
        <>
            <h4 className='mb-3 text-center'>Select LiquidQR Type</h4>
            <button className={styles.linkBtn} onClick={() => setLinking(true)}><i className='far fa-object-exclude' /> Link to an existing QR</button>
            <div className={styles.separator}>or</div>
            <div className={styles.checkboxWrap}>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='link-type-label' className={styles.checkbox} value='link' onChange={(e) => e.target.checked && handleSelect('link')} ></input>
                    <label htmlFor='link-type-label' className={styles.label}><i className='fa-solid fa-link-simple'></i>Link</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='pdf-type-label' className={styles.checkbox} value='pdf' onChange={(e) => e.target.checked && handleSelect('pdf')} ></input>
                    <label htmlFor='pdf-type-label' className={styles.label}><i className='fa-solid fa-file-pdf'></i>PDF</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='menu-type-label' className={styles.checkbox} value='menu' onChange={(e) => e.target.checked && handleSelect('menu')} disabled={disabledQRs}></input>
                    <label htmlFor='menu-type-label' className={styles.label}><i className='fa-solid fa-fork-knife'></i>Menu</label>
                </div>
            </div>
            <div className={styles.checkboxWrap} style={{marginTop: '1rem'}}>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='survey-type-label' className={styles.checkbox} value='survey' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='survey-type-label' className={styles.label}><i className='fa-regular fa-square-poll-vertical'></i>Liquid Feedback</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='liquid-label-type-label' className={styles.checkbox} value='liquid-label' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='liquid-label-type-label' className={styles.label}><i className='fa-solid fa-tag'></i>Liquid Label</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='tracker-type-label' className={styles.checkbox} value='tracker' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='tracker-type-label' className={styles.label}><i className='fa-solid fa-clipboard-list-check'></i>Tracker</label>
                </div>
            </div>
            <div className={styles.checkboxWrap} style={{marginTop: '1rem'}}>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='bitsignal-type-label' className={styles.checkbox} value='bitsignal' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='bitsignal-type-label' className={styles.label}><i className='fak fa-bitsignal'></i>BitSignal</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='review-link-type-label' className={styles.checkbox} value='review-link' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='review-link-type-label' className={styles.label}><i className='fa-regular fa-list-ul'></i>Review Link</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='microsite-type-label' className={styles.checkbox} value='microsite' onChange={(e) => e.target.checked && handleSelect(e.target.value)} disabled={disabledQRs}></input>
                    <label htmlFor='microsite-type-label' className={styles.label}><i className='fa-solid fa-square-list'></i>Microsite</label>
                </div>
            </div>
            <div className={styles.checkboxWrap} style={{marginTop: '1rem'}}>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='youtube-type-label' className={styles.checkbox} value='youtube' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='youtube-type-label' className={styles.label}><i className='fa-brands fa-youtube'></i>YouTube</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='tiktok-type-label' className={styles.checkbox} value='tiktok' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='tiktok-type-label' className={styles.label}><i className='fa-brands fa-tiktok'></i>TikTok</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='linkedin-type-label' className={styles.checkbox} value='linkedin' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='linkedin-type-label' className={styles.label}><i className='fa-brands fa-linkedin'></i>LinkedIn</label>
                </div>
            </div>
            <div className={styles.checkboxWrap} style={{marginTop: '1rem'}}>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='brochure-type-label' className={styles.checkbox} value='brochure' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='brochure-type-label' className={styles.label}><i className='fa-solid fa-file'></i>Brochure</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='landing-page-type-label' className={styles.checkbox} value='landing-page' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='landing-page-type-label' className={styles.label}><i className='fa-solid fa-browser'></i>Landing Page</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='workout-log-type-label' className={styles.checkbox} value='workout-log' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='workout-log-type-label' className={styles.label}><i className='fa-solid fa-person-walking'></i>Workout Log</label>
                </div>
            </div>
            <div className={styles.checkboxWrap} style={{marginTop: '1rem'}}>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='service-log-type-label' className={styles.checkbox} value='service-log' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='service-log-type-label' className={styles.label}><i className='fa-regular fa-table-list'></i>Service Log</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='inventory-type-label' className={styles.checkbox} value='inventory' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='inventory-type-label' className={styles.label}><i className='fa-solid fa-warehouse-full'></i>Inventory</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='assets-type-label' className={styles.checkbox} value='assets' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='assets-type-label' className={styles.label}><i className='fas fa-shelves'></i>Assets</label>
                </div>
            </div>
            <div className={styles.checkboxWrap} style={{marginTop: '1rem'}}>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='review-type-label' className={styles.checkbox} value='review' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='review-type-label' className={styles.label}><i className='fa-solid fa-star-sharp-half-stroke'></i>Review Survey</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='location-type-label' className={styles.checkbox} value='location' onChange={(e) => e.target.checked && handleSelect(e.target.value)} disabled={disabledQRs}></input>
                    <label htmlFor='location-type-label' className={styles.label}><i className='fa-solid fa-map-location'></i>Location</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='appointment-type-label' className={styles.checkbox} value='appointment' onChange={(e) => e.target.checked && handleSelect(e.target.value)} disabled={disabledQRs}></input>
                    <label htmlFor='appointment-type-label' className={styles.label}><i className='fa-solid fa-calendar-days'></i>{window.innerWidth > 480 ? 'Appointment' : 'Appt.'}</label>
                </div>
            </div>
            <div className={styles.checkboxWrap} style={{marginTop: '1rem'}}>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='maintenance-tracker-type-label' className={styles.checkbox} value='maintenance-tracker' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='maintenance-tracker-type-label' className={styles.label}><i className='fa-regular fa-screwdriver-wrench'></i>Maintenance Tracker</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='vehicle-log-type-label' className={styles.checkbox} value='vehicle-log' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='vehicle-log-type-label' className={styles.label}><i className='fa-regular fa-car-side'></i>Vehicle Log</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='dbc-type-label' className={styles.checkbox} value='dbc' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='dbc-type-label' className={styles.label}><i className='fak fa-bitsignal'></i>Digital Business Card</label>
                </div>
            </div>
            <div className={styles.checkboxWrap} style={{marginTop: '1rem'}}>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='user-manual-type-label' className={styles.checkbox} value='user-manual' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='user-manual-type-label' className={styles.label}><i className='fa-regular fa-book'></i>User Manual</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='google-review-type-label' className={styles.checkbox} value='google-review' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='google-review-type-label' className={styles.label}><i className='fa-brands fa-google'></i>Google Review</label>
                </div>
                <div className={styles.inputGroup}>
                    <input type='checkbox' id='container-label-type-label' className={styles.checkbox} value='container-label' onChange={(e) => e.target.checked && handleSelect(e.target.value)} ></input>
                    <label htmlFor='container-label-type-label' className={styles.label}><i className='fa-regular fa-tag'></i>Container Label</label>
                </div>
            </div>
        </>
    );
};

export default TypeSelect;