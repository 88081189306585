import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Nickname from '../../../Nickname';
import styles from './AppointmentOptions.module.css';

const AppointmentOptions = (props) => {
    const { qr_data, dataDispatch } = props;

    return (
        <div className={styles.container}>
            <OverlayTrigger
                placement='top'
                delay={{show: 250, hide: 400}}
                trigger={["click", "hover"]}
                overlay={<Tooltip><p>URL must be a Calendly or Google Calendar link.</p></Tooltip>}
            >
                <h5 className='mb-2'>Appointment Link <i className="fa-regular fa-circle-info"></i></h5>
            </OverlayTrigger>
            <Nickname qr_data={qr_data} dataDispatch={dataDispatch} />
            <div className={styles.inputGroup}>
                <input type='url' id='appointment-label' className={styles.input} onChange={e => dataDispatch({type: 'redirect', data: e.target.value})} value={qr_data?.redirect_url} />
                <label htmlFor='appointment-label' className={styles.label} style={{marginRight:'0.5rem'}}>eg. Calendly (URL)</label>
            </div>   
        </div>
    );
};

export default AppointmentOptions;