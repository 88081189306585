import React, { useEffect, useMemo } from 'react';
import styles from './BitSignalOptions.module.css';
import { useAuth } from '../../../../../utils/Auth/index.js';
import { auth } from '../../../../../firebase/index.js';
import API from '../../../../../API/index.js';
import Nickname from '../../../Nickname/index.js';
import BitSignalLogo from '../../../../Images/bit-signal-logo.png';

const BitSignalOptions = (props) => {
    const { id, qr_data, dataDispatch } = props;
    const { userObj } = useAuth();
    const { bitSignalAuth } = API;
    const uid = useMemo(() => auth?.currentUser?.uid, [auth?.currentUser?.uid]);

    const handleSaveAuth = async (obj) => {
        try {
            await bitSignalAuth(obj);
            dataDispatch({ type: 'bitsignal', data: { uid: obj.uid, authenticated: true } });
        } catch (err) {
            console.log(err);
        };
    };
    
    const handleActiveAuthentication = () => dataDispatch({ type: 'bitsignal', data: { uid: userObj?.bitsignal?.uid, authenticated: true } });

    const handleDisconnectBitSignal = async () => {
        try {
            dataDispatch({type: 'bitsignal', data: { uid: '', authenticated: false } });
        } catch (err) {
            console.error(err);
        };
    };

    const handleSignOutBitSignal = async () => {
        try {
            await bitSignalAuth({ uid: '' });
        } catch (err) {
            console.error(err);
            return;
        };
    };
    
    const handleLogin = () => window.open(`${process.env.REACT_APP_BS_ORIGIN}/authenticate?lqr=${uid}&qrid=${id}`, 'authWindow', 'width=500,height=500');
    const handleCreate = () => window.open(`${process.env.REACT_APP_BS_GENERATOR_ORIGIN}?lqr=${uid}&qrid=${id}`, 'authTab');

    useEffect(() => {
        const handleEventListener = e => {
            if (e?.origin !== process.env.REACT_APP_BS_ORIGIN) return;
            
            const bitsignalObj = e?.data;
            
            if (bitsignalObj?.uid) {
                handleSaveAuth(bitsignalObj);
            };
        };
        
        const handleGeneratorListener = e => {
            if (e?.origin !== process.env.REACT_APP_BS_GENERATOR_ORIGIN) return;
            
            const bsGenObj = e?.data;
            
            if (bsGenObj?.uid) {
                handleSaveAuth(bsGenObj);
            };
        };

        window?.addEventListener('message', handleEventListener);
        window?.addEventListener('message', handleGeneratorListener);

        return () => {
            window.removeEventListener('message', handleEventListener);
            window.removeEventListener('message', handleGeneratorListener);
        };
    },[]);

    const bodyReturn = () => {
        if (userObj === null) {
            return <i className='far fa-spinner fa-spin' />;
        } else if (qr_data?.bitsignal?.uid && qr_data?.bitsignal?.authenticated) {
            return (
                <div className={styles.authenticatedContainer}>
                    <span>Authenticated</span>
                    <button className={styles.btn} onClick={handleDisconnectBitSignal} >Disconnect BitSignal</button>
                </div>
            );
        } else if (userObj?.bitsignal && userObj?.bitsignal?.uid) {
            return (
                <div className={styles.authenticatedContainer} >
                    <span>Use your active authentication or <button className={styles.loginBtn} onClick={handleSignOutBitSignal} >sign out</button>?</span>
                    <button className={styles.btn} onClick={handleActiveAuthentication} >Active Authentication</button>
                </div>
            );
        } else {
            return (
                <div className={styles.authButtons}>
                    <button aria-label='Open BitSignal Card Generator' className={styles.btn} onClick={handleCreate} >Create Your Digital Business Card</button>
                    <span className={styles.span}>Have an account? Connect <button aria-label='Open BitSignal login' className={styles.loginBtn} onClick={handleLogin}>here</button>.</span>
                </div>
            );
        };
    };
    
    return (
        <div className={styles.container}>
            <Nickname qr_data={qr_data} dataDispatch={dataDispatch} />
            <img src={BitSignalLogo} />
            <div>
                <h5>Unlock your business's full potential</h5>
                <ul className={styles.list}>
                    <li>Share your contact information</li>
                    <li>Link to your products or services</li>
                    {/* <li>Flex on the haters</li> */}
                </ul>
            </div>
            {bodyReturn()}
        </div>
    );
};

export default BitSignalOptions;