import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../../utils/Auth';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, useSortable, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Column from '../Column';
import styles from './ColumnDisplay.module.css';

const SortableColumn = ({ id, column, setSelected, dataDispatch }) => {
    const { listeners, transform, transition, isDragging, setNodeRef, setActivatorNodeRef } = useSortable({
        id: id
    });

    const style = transform ? {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : undefined,
    } : undefined;

    if (column?.type !== 'date') {
        return (
            <div ref={setNodeRef} style={style} className={styles.column} key={id} >
                <div ref={setActivatorNodeRef} {...listeners} className={styles.colDragIcon}><i className='fa-solid fa-grip-dots' /></div>
                <input id='required-checkbox' type='checkbox' className={styles.reqCheckbox} defaultChecked={column?.required} onChange={() => dataDispatch({ type: 'tracker-required-state', data: column })} />
                <div className={styles.colDetailContainer} onClick={() => setSelected(column)} >
                    <div className={styles.colDetails}>{column.name}</div>
                    <div className={styles.colDetails}>{column.type}</div>
                </div>
                <i onClick={() => dataDispatch({ type: 'tracker-archive-state', data: column })} className={`fa-solid fa-trash-can ${styles.deleteColumnIcon}`} />          
            </div>
        );
    };
};

const ColumnDisplay = (props) => {
    const { columns, qr_data, dataDispatch } = props;
    const { liquidQRs } = useAuth();

    const [ selected, setSelected ] = useState(null);
    const [ originalQRTracker, setOriginalQRTracker ] = useState(liquidQRs?.find(qr => qr?.data?.nickname === qr_data?.nickname));
    const [ columnOrder, setColumnOrder ] = useState(columns?.length ? columns?.map(col => col?.id): []);

    useEffect(() => {
        if (liquidQRs?.length > 0) {
            setOriginalQRTracker(liquidQRs?.find(qr => qr?.data?.nickname === qr_data?.nickname));
        } else {
            setOriginalQRTracker(null);
        };
    },[liquidQRs, qr_data?.nickname]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setColumnOrder((items) => {
                const activeIndex = items.indexOf(active.id);
                const overIndex = items.indexOf(over.id);
                
                dataDispatch({ type: 'tracker-columns', data: reorder(columns, activeIndex, overIndex) });
                return arrayMove(items, activeIndex, overIndex);
            });
        };
    };

    return (
        <>
        <div className={styles.headerContainer}>
            <h4>Active Columns</h4>
        </div>
        {columns?.filter(col => !col?.archived)?.length > 0 ?
            <DndContext collisionDetection={closestCenter} onDragEnd={onDragEnd} >
                <div className={styles.droppableDiv} >
                    <p className={styles.requiredLabel} >Required</p>
                    <SortableContext items={columns.map(col => col.id)} strategy={verticalListSortingStrategy} >
                        {columns?.filter(col => !col?.archived)?.map((column) => (
                            <SortableColumn key={column?.id} id={column?.id} column={column} setSelected={setSelected} dataDispatch={dataDispatch} />
                        ))}
                    </SortableContext>
                </div>
            </DndContext>
        :
            <></>
        }
        <Column originalQRTracker={originalQRTracker} columns={qr_data?.tracker?.columns} dataDispatch={dataDispatch} selected={selected} setSelected={setSelected} />
        <h4 className='mt-3'>Archived Columns</h4>
        {columns?.filter(col => col?.archived)?.length > 0 ?
            <div style={{ position: 'relative', width: '100%' }} >
                <p className={styles.requiredDelete}>Delete</p>
                {columns?.filter(col => col?.archived)?.map(column => (
                    <div key={column?.id} className={styles.column} >
                        <div className={styles.colDragIcon}><i className='fa-solid fa-grip-dots' /></div>
                        <div className={styles.archiveSelected} onClick={() => dataDispatch({ type: 'tracker-archive-state', data: column })} ><p>Activate</p></div>
                        <div className={styles.colDetailContainerArchived}>
                            <div className={styles.colDetails}>{column.name}</div>
                            <div className={styles.colDetails}>{column.type}</div>
                        </div>
                        <i className={`fa-solid fa-xmark  ${styles.deleteColumnIcon}`} onClick={() => dataDispatch({ type: 'tracker-delete-column', data: column.id })}/>
                    </div>
                ))}
            </div>
        :
            <div>None</div>
        }
        </>
    );
};

export default ColumnDisplay;