import React from 'react';
import Tags from '../../Tags';
import Categories from '../../Categories';
import styles from './BitSignal.module.css';

const BitSignal = (props) => {
    const { qr_data, dataDispatch, categoryList, setCategoryList, plan, plan_id } = props;

    return (
        <>
            <h5 className={styles.header}>BitSignal</h5>
            <Categories categoryList={categoryList.filter(cat => cat.id !== 'Uncategorized')} setCategoryList={setCategoryList} qr_data={qr_data} dataDispatch={dataDispatch} plan_id={plan_id} plan={plan} />
            <Tags qr_data={qr_data} dataDispatch={dataDispatch} />
        </>
    );
};

export default BitSignal;