import React from 'react';
import QuestionDisplay from '../QuestionDisplay';
import { useTypeHeaderIcon } from '../../../../../utils/hooks/useTypeHeaderIcon';
import Nickname from '../../../Nickname';
import styles from './SurveyOptions.module.css';

const SurveyOptions = (props) => {
    const { qrType, qr_data, dataDispatch } = props;
    const { header } = useTypeHeaderIcon(qrType);
    const handleTitle = e => {
        dataDispatch({type: 'survey-options-title', data: e.target.value});
    };

    const handleMessage = e => {
        dataDispatch({type: 'survey-options-message', data: e.target.value});
    };

    const handleUrl = e => {
        dataDispatch({type: 'survey-options-url', data: e.target.value});
    };

    return (
        <div className={styles.container}>
            <h5 className={styles.header}>Liquid Feedback</h5>
            <Nickname qr_data={qr_data} dataDispatch={dataDispatch} />
            <h5>{header} Question(s)</h5>
            <div className={styles.columnList}>
                <QuestionDisplay questions={qr_data?.survey?.questions?.sort((a,b) => a.archived - b.archived)} qr_data={qr_data} dataDispatch={dataDispatch}  />
            </div>
            <div className={styles.inputGroup}>
                <input id='post-survey-title' type='text' className={styles.input} maxLength='50' defaultValue={qr_data?.survey?.post_survey_options?.title} onChange={handleTitle} />
                <label htmlFor='post-survey-title' className={styles.label}>Post {header} Title</label>
            </div>
            <div className={styles.inputGroup}>
                <input id='post-survey-message' type='text' className={styles.input} maxLength='100' defaultValue={qr_data?.survey?.post_survey_options?.message} onChange={handleMessage} />
                <label htmlFor='post-survey-message' className={styles.label}>Post {header} Message</label>
            </div>
            <div className={styles.inputGroup}>
                <input id='post-survey-url' type='url' className={styles.input} maxLength='100' defaultValue={qr_data?.survey?.post_survey_options?.url} onChange={handleUrl} />
                <label htmlFor='post-survey-url' className={styles.label}>Post {header} URL</label>
            </div>
        </div>
    );
};

export default SurveyOptions;