import React, { useState, useEffect, useMemo } from 'react';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import adminAPI from '../../API/adminAPI';
import API from '../../API';
import { useAuth } from '../../utils/Auth';
import { useKeyGen } from '../../utils/keygen';
import { handleAddQr } from '../../utils/QRFunctions/addQrFromBs';
import { useCheckEmail } from '../../utils/hooks/useCheckEmail';
import styles from './BSEntAuth.module.css';

// Custom hook for loading complete
const useLoading = (props) => {
    const { type, currentUser, userObj } = props;
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        let mounted = true;
        const handleSetLoading = () => mounted && setLoading(false);

        if (!type) handleSetLoading();
        if (type === 'login' && currentUser && userObj) handleSetLoading();
        if (!currentUser && !userObj && type === 'signup') handleSetLoading();

        return () => mounted = false
    },[currentUser, type, userObj]);

    return useMemo(() => loading, [loading]);
};

export default function BSEntAuth(props) {
    // Props and initialization of tools
    const { email, password, tag, ent, type } = props;
    const { login, logout, create, userObj, currentUser, refreshCurrentUser } = useAuth();
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    
    // Timezone options for sign up
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { options } = useTimezoneSelect('original', allTimezones);
    const timezoneObj = options.find((opt) => opt.value === timezone);
    
    const qrId = useKeyGen();
    const qrStyle = searchParams.get('qr_style');
    const completeParam = searchParams.get('comp');
    const signUpComplete = completeParam === 'true' ? true : false;

    // API calls
    const { completeBSEntAccount, decryptBsPassword } = adminAPI;
    const { addUIDtoDB, updateUserObject } = API;

    // States
    const [ inputs, setInputs ] = useState({ email: '', password: '' });
    const [ createBool, setCreateBool ] = useState(false);
    const [ authState, setAuthState ] = useState({
        email: email,
        ent: ent,
        error: false,
        formattedTimezone: { label: timezoneObj.label, offset: timezoneObj.offset, value: timezoneObj.value },
        message: '',
        password: password ?? '',
        tag: tag ?? '',
        type: type ?? (password ? 'login' : 'signup'),
        uid: ''
    });

    const emailCheck = useCheckEmail({ email: inputs?.email ? inputs.email : authState.email, type: 'new' });
    const loading = useLoading({ type, currentUser, userObj });
    const display = useMemo(() => ({
        back: type === 'signup',
        login: type === 'login' && signUpComplete,
        signup: type === 'signup',
        complete: type === 'login' && !signUpComplete
    }),[]);

    const signUpValid = () => {
        if (!emailCheck?.valid) {
            setAuthState(prev => ({ ...prev, error: true, message: 'Email address in use' }));
        } else if (inputs?.password?.length < 6) {
            setAuthState(prev => ({ ...prev, error: true, message: 'Password must be at least 6 characters long' }));
        } else {
            setAuthState(prev => ({ ...prev, email: inputs.email, password: inputs.password }));
            if (userObj && !userObj?.signUpComplete) {
                handleUpgradeFullAccount();
            } else {
                handleSignup(true);
            };
        };
    };

    const handleEncryptionDecryption = async (type, pw=null) => {
        try {
            const { data } = await decryptBsPassword({ password: pw ?? authState.password, tag: authState.tag, type: type });
            return data;
        } catch (err) {
            console.error(err);
            throw(new Error(err));
        };
    };

    const handlePostMessage = (obj) => {
        try {
            const uid = currentUser?.uid ?? obj?.data?.uid;
            const navQrId = (authState?.type === 'signup' && qrId) ? qrId : (authState?.type === 'login' && userObj.bitsignal.qrId) ? userObj.bitsignal.qrId : '';
            window.opener.postMessage(obj, process.env.REACT_APP_BS_ORIGIN);
            if (navQrId && uid) {
                navigate(`/collection/edit?qrid=${navQrId}&edit=true&ref=bs&ent=true&uid=${uid}`);
            };
        } catch (err) {
            console.error(err);
        };
    };
    
    const handleLogin = useDebouncedCallback(async () => {
        if (!authState.email || !authState.password || !authState.tag) throw new Error('no auth');

        try {
            const decPass = await handleEncryptionDecryption('login');
            const { dec } = decPass;
            if (!dec) throw('No dec');
            login(authState.email, dec);
        } catch (err) {
            throw new Error('bs login error', { cause: err });
        };
    }, 200, { trailing: false, leading: true });
    
    const handleUpgradeFullAccount = async () => {
        try {
            setAuthState(prev => ({ ...prev, loading: true, error: false, message: '' }));
            const encPass = await handleEncryptionDecryption('signup', inputs.password);
            const { enc, iv, tag } = encPass;
            await completeBSEntAccount({ email: inputs.email, password: inputs.password }).then(async () => await refreshCurrentUser(inputs));
            await updateUserObject(currentUser.uid, { signUpComplete: true });
            handlePostMessage({ type: 'complete', data: { email: inputs.email, password: `${iv}.${enc}`, tag: tag, signUpComplete: true } });
        } catch (err) {
            console.error('hit error', err);
            setAuthState(prev => ({ ...prev, loading: false, error: true, message: 'An error occurred.' }));
        };
    };

    const handleSignup = async (signup) => {
        const emailValue = signup ? inputs.email : authState.email;
        if (!emailValue) throw new Error('no auth');

        try {
            const encPass = await handleEncryptionDecryption('signup', ((signup && inputs?.password) ? inputs.password : null));
            const { enc, dec, iv, tag } = encPass;
            const { user } = await create(emailValue, dec);
            const userData = {
                bitsignal: {
                    authenticated: true,
                    dateConnected: Date.now(),
                    ent: ent,
                    qrId: qrId
                },
                categories: [],
                credit_balance: 0,
                org_id: user.uid,
                plan: {
                    analytics: 0,
                    categories: 1,
                    liquid_qrs: 1,
                    plan_id: 0,
                    support: 0,
                    team_size: 1
                },
                signUpComplete: signup ?? false,
                sonar_length: 3600000,
                team: [user.uid],
                timezone: authState.formattedTimezone
            };

            await addUIDtoDB(userData);
            await handleAddQr({ org_id: user.uid, ent: ent, qrId: qrId, qrStyle: qrStyle ? JSON.parse(qrStyle) : null  });

            handlePostMessage({ type: 'new', data: { uid: user.uid, email: emailValue, password: `${iv}.${enc}`, tag: tag, qrId: qrId, signUpComplete: (createBool && inputs.email && inputs.password && emailValue === inputs.email) ? true : false }});
        } catch (err) {
            console.error('err');
            throw new Error('bs login error', { cause: err });
        };
    };

    const handleBack = () => {
        setCreateBool(false);
        setInputs({ email: '', password: '' });
    };

    useEffect(() => {
        let mounted = true;
        if (display.complete && currentUser && currentUser?.hasOwnProperty('email') && (currentUser?.email?.toLowerCase() !== email?.toLowerCase() && currentUser?.email?.toLowerCase() !== inputs.email?.toLowerCase())) {
            mounted && logout().then(() => window.location.reload());
        } else if (display.complete && !currentUser) {
            handleLogin();
        };

        return () => mounted = false;
    },[display.complete, currentUser?.email]);

    useEffect(() => {
        let mounted = true;

        if (loading && display.signup && currentUser) {
            mounted && logout().then(() => window.location.reload());
        };

        if (display.login && loading) {
            (mounted && !currentUser) && handleLogin();
        } else if (!loading && authState.email && authState.password && userObj?.bitsignal) {
            if (userObj?.signUpComplete) {
                mounted && navigate(`/collection/edit?qrid=${userObj.bitsignal.qrId}&edit=true&ref=bs&ent=true&uid=${currentUser?.uid}`);
            } else {
                mounted && setCreateBool(true);
            };
        } else {
            return;
        };

        return () => mounted = false;
    },[loading, currentUser]);

    if (loading || (authState.type === 'login' && (!currentUser || !userObj))) {
        return (
            <div className={styles.container}>
                <i className='far fa-spinner fa-spin' />
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <h3 className={styles.header} >BitSignal <i className='far fa-xmark' style={{ fontWeight: 'normal' }} /> LiquidQR</h3>
            <span>{display.complete ? 'Add an email and password to create a full LiquidQR account.' : 'We handle the authentication. You handle the creativity.'}</span>
            {((display.complete || display.signup) && createBool) ?
                <>
                <div className={styles.inputsCont}>
                    {display.signup ? <button className={styles.backBtn} onClick={handleBack} ><i className='fa-solid fa-arrow-left' /> Back</button> : <></>}
                    <div className={styles.inputWrap} >
                        <input id='ent-reg-email' type='email' className={styles.input} value={inputs.email} onChange={(e) => setInputs(prev => ({ ...prev, email: e.target.value }))} ></input>
                        <label htmlFor='ent-reg-email' className={styles.label} >Email</label>
                    </div>
                    <div className={styles.inputWrap} >
                        <input id='ent-reg-password' type='password' className={styles.input} value={inputs.password} onChange={(e) => setInputs(prev => ({ ...prev, password: e.target.value }))} ></input>
                        <label htmlFor='ent-reg-password' className={styles.label} >Password</label>
                    </div>
                    <button className={styles.btn} onClick={signUpValid} style={{ margin: 'auto' }}>Create Account</button>
                    {(display.complete && currentUser?.hasOwnProperty('uid')) ? <Link className={styles.editLink} to={`/collection/edit?qrid=${userObj.bitsignal.qrId}&edit=true&ref=bs&ent=true&uid=${currentUser?.uid}`} >Continue to edit BitSignal QR</Link> : <></>}
                </div>
                {authState.error ? <span>{authState.message}</span> : <></>}
                </>
            :
                <>
                    <button onClick={() => setCreateBool(true)} className={styles.btn} style={{ marginTop: '1rem' }} >Sign Up</button>
                    <span style={{ margin: '1rem 0' }}>Get straight to designing your LiquidQR for BitSignal</span>
                    <button className={styles.btn} onClick={() => handleSignup()} >Start Creating</button>
                </>
            }
            {display.signup ? <span className={styles.checkboxlabel}>By clicking Sign Up or Start Creating, you agree to the Liquid QR terms of service</span> : <></>}
        </div>
    );
};
