import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import QRRef from '../QRSwitch/QRRef';
import { DashChart } from './DashChart';
import '../../utils/BadCodes';
import styles from './Dashboard.module.css';

export default function Dashboard(props) {
    // ----- STATE INITIALIZATION -----
    const { analytics, liquidQRs, plan } = props;
    const { plan_id, analytics: analytics_id, liquid_qrs } = plan;

    const navigate = useNavigate();

    const [ activeQRs, setActiveQRs ] = useState();
    const [ analyticsLength, setAnalyticsLength ] = useState(0);
    const [ topQRs, setTopQRs ] = useState([]);
    const [ atMaxQRs, setAtMaxQRs ] = useState(true);

    // Gets activeQRs for quick view analytics
    useEffect(() => {
        if (liquidQRs) {
            setActiveQRs(liquidQRs);
        };
    },[liquidQRs]);

    useEffect(() => {
        if(liquidQRs && liquid_qrs) {
            if (liquidQRs?.filter(obj => !obj.id.startsWith('-'))?.length >= liquid_qrs) {
                setAtMaxQRs(true);
            } else {
                setAtMaxQRs(false);
            }
        }
    },[liquidQRs, liquid_qrs]);
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ------ HELPER FUNCTIONS ------
    // Handles different cases if analytics exist and if there are enough qrs to fill the three slots
    useEffect(() => {
        // Analytics and liquidQRs both exist
        const analyticsIDs = Object.keys(analytics);
        const arr = activeQRs?.filter(obj => analyticsIDs.includes(obj.id));
        
        if (arr?.length > 0) {
            arr.sort((a,b) =>  analytics[b.id].length - analytics[a.id].length);
            setTopQRs(arr.slice(0,5));
        };
    },[analytics, activeQRs]);

    useEffect(() => {
        if (analytics) {
            let i = 0;

            for (const key in analytics) {
                i += analytics[key].length;
            };

            setAnalyticsLength(i);
        };
    },[analytics]);
    // ------ END HELPER FUNCTIONS ------
    // ----------------------------------------------

    // ----- RETURN CONDITIONALS -----
    const handleTopQRs = () => {
        if (activeQRs?.length > 0 && analytics_id > 0) {
            return (<>
                <h5 className='mb-2'>Top QRs</h5>
                <div className={styles.topQRsContainer}>
                    {topQRs?.length > 0 ?
                        topQRs.map((obj, index, arr) => {
                            return (
                                <div className={styles.topQRsWrap} key={obj.id} style={{top: `${(index) * 7.5}rem`}}>
                                    <div className={`my-2 ${styles.topQRs}`}>
                                        <QRRef qr_options={{...obj?.data?.qr_style, height: 50, width: 50}} />
                                        <p className='my-0' style={{width: '100%', overflow:'hidden', whiteSpace:'nowrap', textOverflow: 'ellipsis'}}><b>Name</b>: {obj?.data?.nickname}</p>
                                        <p className='my-0'><b>Scans</b>: {analytics && analytics[obj?.id] ? analytics[obj?.id].length : 0}</p>
                                    </div>
                                    {arr.length - 1 > index && <hr className='mt-1 mb-2'></hr>}
                                </div>
                            );
                        })
                    :
                        <h4 style={{margin: '0'}}>No QRs to display</h4>
                    }
                </div>
            </>);
        } else if (analytics_id < 1) {
            return(
                <>
                    <h5 className='mb-3'>Top QRs</h5>
                    <div  className={`mb-2 mt-2 ${styles.topQRs}`} >
                        <a className={styles.upgrade} href='https://app.liquidqr.com/upgrade' target='_blank' rel="noreferrer">
                            Upgrade
                        </a> 
                        to see your Top QRs.
                    </div>
                </>
            );
        } else {
            return(<h5 className='mb-3 text-center'>Create QRs in the QR Codes section!</h5>);
        };
    };
    // ----- END RETURN CONDITIONALS -----
    // ----------------------------------------------
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.topRow}>
                <div className={`${styles.topRowInner} ${atMaxQRs ? styles.disabled : ''}`} onClick={atMaxQRs ? () => {} : () => navigate('/collection/new')}>
                    <div className={styles.topSection}>
                        <i className={`far fa-plus ${styles.iconOnly}`} />
                    </div>
                    <div className={styles.bottomSection}>
                        <p className='mb-0'>Create QR</p>
                    </div>
                </div>
                <div className={styles.topRowInner} onClick={() => navigate('/collection')}>
                    <div className={styles.topSection}>
                        <i className='fak fa-liquidqr' />
                        <h3 className='mb-0'>{activeQRs?.length}</h3>
                    </div>
                    <div className={styles.bottomSection}>
                        <p className='mb-0'>Your QRs</p>
                    </div>
                </div>

                    {(analytics_id > 0 && plan_id > 0) ?
                        <>
                            <div className={styles.topRowInner} onClick={() => navigate('/analytics')}>
                                <div className={styles.topSection}>
                                    <i className='far fa-scanner-touchscreen' />
                                    <h3 className='mb-0'>{analyticsLength}</h3>
                                </div>
                                <div className={styles.bottomSection}>
                                    <p className='mb-0'>Total Scans</p>
                                </div>
                            </div>
                        </>
                    :
                        <div className={styles.topRowInner} onClick={() => navigate('/upgrade')}>
                            <div className={styles.topSection}>
                                <i className={`far fa-scanner-touchscreen ${styles.iconOnly}`} />
                            </div>
                            <div className={styles.bottomSection}>
                                <p className='mb-0'>Upgrade</p>
                            </div>
                        </div>
                    }
                    <div className={styles.topRowInner} onClick={() => window.open('https://liquidqr.com/store', '_blank')}>
                        <div className={styles.topSection}>
                            <i className={`far fa-bag-shopping ${styles.iconOnly}`} />
                        </div>
                        <div className={styles.bottomSection}>
                            <p className='mb-0'>Order Stickers</p>
                        </div>
                    </div>
                </div>
                <div className={styles.main}>
                    <div className={styles.mainTop}>
                        <div className={styles.mainTopLeft}>
                            <h5>LiquidQR Scans this Week</h5>
                            <DashChart analytics={analytics} liquidQRs={liquidQRs}/>
                        </div>
                        <div className={styles.mainTopRight}>
                            {handleTopQRs()}
                            {topQRs?.length >= 4 ?
                                <div className={styles.arrow}>
                                    <span>SCROLL</span>
                                    <i className='far fa-chevron-down' />
                                </div>
                            :
                                <></>
                            }
                        </div>
                    </div>
                    {/* <div className={styles.mainBottom}>
                        <div className={styles.mainBottomLeft}>
                            <Link to='/collection'><button className={styles.mainTwoBtn}>
                                <i className='far fa-qrcode me-2' />
                                QR Codes
                            </button></Link>
                            {(analytics_id > 1 && plan_id > 2) ?
                                <Link to='/analytics'><button className={styles.mainTwoBtn}>
                                    <i className='far fa-analytics me-2' />
                                    Analytics
                                </button></Link>
                            : 
                                <></>
                            }
                            <Link to='/settings'><button className={styles.mainTwoBtn}>
                                <i className='far fa-gear me-2' />
                                Settings
                            </button></Link>
                        </div>
                        <div className={styles.mainBottomRight} />
                    </div> */}
                </div>
            </div>
        </div>
    );
};