import React, { useEffect, useState, useRef } from 'react';

export const useClickOutside = (initialState) => {
    const [ open, setOpen ] = useState(initialState);
    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }            
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    },[]);

    return [ref, open, setOpen];
};