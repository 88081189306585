import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Nickname from '../../../Nickname';
import styles from './MenuOptions.module.css';

const MenuOptions = (props) => {
    const { qr_data, dataDispatch, id, qrFiles, setQrFiles, setDeleteFiles } = props;

    const [ file, setFile ] = useState(null);
    const [ menuName, setMenuName ] = useState('');
    const [ menuNameArr, setMenuNameArr ] = useState(qr_data?.menu?.length > 0 ? qr_data.menu.map(obj => obj.name) : []);
    const [ menuType, setMenuType ] = useState('');
    const [ url, setUrl ] = useState('');
    const acceptableFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

    useEffect(() => {
        if (qr_data?.menu?.length > 0) {
            setMenuNameArr(qr_data.menu.map(obj => obj.name));
        };
    },[qr_data?.menu]);

    // ----- FORM FUNCTIONS -----
    const handleRemoveMenu = (index, name) => {
        if (qrFiles?.find(file => file?.name === name)) {
            setQrFiles(prev => prev.filter(file => file?.name !== name));
            setMenuNameArr(prev => prev.filter(menu => menu !== name));
        } else if (qr_data.menu?.length > 0) {
            qr_data.menu[index]?.redirect?.includes(id) && setDeleteFiles(prev => prev.concat(qr_data.menu[index].redirect));
            setMenuNameArr(prev => prev.filter(name => name !== qr_data.menu[index].name));
        };

        dataDispatch({type: 'remove-menu', data: index});
    };

    const handleMenuUpload = () => {
        if (file) {
            setQrFiles(prev => prev.concat({ data: file, name: menuName }));
            dataDispatch({type: 'menu', data: {name: menuName, redirect: `${id}/${menuName}.${file.type.split('/')[1]}`}});
        } else {
            dataDispatch({type: 'menu', data: {name: menuName, redirect: url}});
        };
        setMenuNameArr(prev => prev.concat(menuName));
        handleFormReset();
    };

    const handleFormReset = () => {
        setMenuName('');
        setUrl('');
        setMenuType('');
        setFile(null);
    };
    // ----- FORM FUNCTIONS -----
    // ----------------------------------------------

    const handleMenuType = () => {
        if (menuType === 'link') {
            return (
                <>
                <h5 className='mb-2'>Add Menu URL</h5>
                <div className={styles.inputGroup}>
                    <input type='url' id='redirect_url-label' className={styles.input} onChange={e => setUrl(e.target.value.includes('http') ? e.target.value : `https://${e.target.value}`)} value={url} />
                    <label htmlFor='redirect_url-label' className={styles.label} style={{marginRight:'0.5rem'}}>Default Menu Link</label>
                </div>
                </>
            );
        } else if (menuType === 'file') {
            return (
                <>
                <div className={styles.uploadWrap} >
                    <input type='file' id='menu-label' className={styles.fileInput} accept='.pdf,.jpg,.jpeg,.png' onChange={(e) => setFile(e.target.files[0])} ></input>
                    <label htmlFor='menu-label'className={styles.fileLabel}>Upload Menu</label>
                </div>
                {file ? 
                    ((!acceptableFileTypes?.includes(file?.type) || file?.size > 5000000) ?
                        <div className={styles.warning} style={{color: 'red', marginBottom: '0'}}>{!acceptableFileTypes?.includes(file?.type) ? 'File must be of type .jpg, .png, or .pdf.' : 'File size exceeds 5MB.'}</div>    
                    :
                        <div className={styles.warning} styles={{lineHeight: '1.5rem', whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>File Selected: <span style={{fontWeight: 'bold'}}>{file?.name}</span></div>
                    ) 
                    : <></>
                }
                </>
            );
        } else {
            return (
                <>
                <h5>Type</h5>
                <div className={styles.typeContainer}>
                    <button className={styles.typeBtn} onClick={() => setMenuType('file')}>File</button>
                    <h5 className='mb-0'>OR</h5>
                    <button className={styles.typeBtn} onClick={() => setMenuType('link')}>Link (URL)</button>
                </div>
                </>
            );
        };
    };

    return (
        <div className={styles.container}>
            <OverlayTrigger 
                placement='top'
                delay={{show: 250, hide: 400}}
                trigger={['click', 'hover']}
                overlay={<Tooltip><p>Give your Menu a meaningful name. Scanning your QR will redirect the user to this Menu's link or file.</p></Tooltip>}
            >
                <h5 className='mb-2'>{qr_data?.menu?.length > 0 ? 'New Menu' : 'Default Menu'} <i className='fa-regular fa-circle-info' /></h5>
            </OverlayTrigger>
            <Nickname qr_data={qr_data} dataDispatch={dataDispatch} />
            <div className={styles.inputGroup}>
                <input type='url' id='menu-name-label' maxLength={15} className={styles.input} onChange={e => setMenuName(e.target.value.replaceAll(' ','_'))} value={menuName} />
                <label htmlFor='menu-name-label' className={styles.label} style={{marginRight:'0.5rem'}}>Menu Name</label>
                {menuNameArr.includes(menuName) ? <p className={styles.dupName}>Menu name cannot match an existing menu name.</p> : <></>}
            </div>
            {handleMenuType()}
            {menuType ? 
                <div className={styles.btnContainer}>
                    <button className={styles.cancelBtn} onClick={handleFormReset}>Reset</button>
                    <button className={styles.addBtn} onClick={handleMenuUpload} disabled={!menuName || Number(qr_data?.menu?.length) > 4 || (menuType === 'file' && (!file || !acceptableFileTypes?.includes(file?.type) || file?.size > 5000000)) || (menuType === 'link' && !url)} >Add Menu</button>
                </div>
            : <></>
            }
            <h5 className='mt-4 mb-0'>Current Menu(s)</h5>
            <div className={styles.remaining}>Menu Slot(s) Remaining: <span style={{fontWeight: 'bold'}}>{5 - Number(qr_data?.menu?.length ?? 0)}</span></div>
            <div className={styles.displayWrap}>
                {qr_data?.menu?.length > 0 ?
                    qr_data.menu.map((obj, index) => (
                        <div className={styles.displayContainer} key={index}>
                            <div className={styles.thumbnailName} >{obj.redirect?.includes(id) ? obj.redirect.split('/')[1] : obj.name}</div>
                            <i className={obj.redirect.includes('http') ? `fa-brands fa-chrome ${styles.displayIcon}` : obj.redirect.split('.')[1] === 'pdf' ? `fa-solid fa-file-pdf ${styles.displayIcon}` : `fa-solid fa-image ${styles.displayIcon}`} />
                            <button className={styles.removeMenuBtn} onClick={() => handleRemoveMenu(index, obj.name)}><i className='fa-solid fa-xmark' /></button>
                        </div>
                    ))
                :
                    <div style={{fontWeight: '600', opacity: '.6'}}>Add a Menu to see it here.</div>
                }
                {/* {qrFiles?.length < 5 ?
                    <div className={styles.displayContainerIconAdd} onClick={() => setShow(true)} >
                        <span className='mb-0'>New Menu</span>
                        <i className='fa-solid fa-plus menu-display-icon' />
                    </div>
                :
                    <></>
                } */}
            </div>
        </div>
    );
};

export default MenuOptions;