import React from 'react';
import ColumnDisplay from '../ColumnDisplay';
import Nickname from '../../../Nickname';
import { useTypeHeaderIcon } from '../../../../../utils/hooks/useTypeHeaderIcon';
import styles from './TrackerOptions.module.css';

const TrackerOptions = (props) => {
    const { qrType, qr_data, dataDispatch } = props;
    const { header } = useTypeHeaderIcon(qrType);

    return (
        <div className={styles.container}>
            <h5 className={styles.header}>{header}</h5>
            <Nickname qr_data={qr_data} dataDispatch={dataDispatch} />
            <div className={styles.columnList}>
                <ColumnDisplay columns={qr_data?.tracker?.columns?.sort((a,b) => a.archived - b.archived)} qr_data={qr_data} dataDispatch={dataDispatch}  />
            </div>
        </div>
    );
};

export default TrackerOptions;