import React from 'react';
import { useTypeHeaderIcon } from '../../../../../utils/hooks/useTypeHeaderIcon';
import Nickname from '../../../Nickname';
import styles from './RedirectLinkOptions.module.css';

const RedirectLinkOptions = (props) => {
    const { qr_data, qrType, dataDispatch } = props;
    const { header } = useTypeHeaderIcon(qrType);

    return (
        <div className={styles.container}>
            <h5 className='mb-2'>{header}</h5>
            <Nickname qr_data={qr_data} dataDispatch={dataDispatch} />
            <div className={styles.inputGroup}>
                <input
                    type='url'
                    id='redirect_url-label'
                    className={styles.input}
                    onChange={e => {
                        dataDispatch({type: 'redirect', data: e.target.value});
                        dataDispatch({type: 'alt-redirect', data: e.target.value, index: 0 });
                    }}
                    value={qr_data.redirect_url}
                />
                <label htmlFor='redirect_url-label' className={styles.label} style={{marginRight:'0.5rem'}}>{header} (URL)</label>
            </div>
        </div>
    );
};

export default RedirectLinkOptions;