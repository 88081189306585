import React from 'react';
import Tags from '../../Tags';
import Categories from '../../Categories';
import { useTypeHeaderIcon } from '../../../../utils/hooks/useTypeHeaderIcon';
import styles from './PDF.module.css';

export default function PDF(props) {
    // ----- STATE INITIALIZATION -----
    const {
        qrType,
        qr_data,
        dataDispatch,
        categoryList,
        setCategoryList,
        plan,
        plan_id
    } = props;

    const { header } = useTypeHeaderIcon(qrType);

    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    return (
        <>
        <h4 className={styles.header}>{header}</h4>
        <Categories categoryList={categoryList.filter(cat => cat.id !== 'Uncategorized')} setCategoryList={setCategoryList} qr_data={qr_data} dataDispatch={dataDispatch} plan_id={plan_id} plan={plan} />
        <Tags qr_data={qr_data} dataDispatch={dataDispatch} />
        </>
    );
};