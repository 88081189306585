import React, { useState, useEffect } from 'react';
import { customAlphabet } from 'nanoid';
import QuestionTypes from '../QuestionTypes';
import { useClickOutside } from '../../../../../utils/hooks/useClickOutside';
import styles from './Question.module.css';

const Question = (props) => {
    const { questions, dataDispatch, selected, setSelected, originalQRSurvey } = props;
    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzQWERTYUIOPASDFGHJKLZXCVBNM', 6);

    const [ ref, open, setOpen ] = useClickOutside(false);

    const handleNewId = () => {
        const newId = nanoid();
        
        if (questions?.find(q => q.id === newId)) {
            return handleNewId();
        };

        return newId;
    };

    const [ question, setQuestion ] = useState(selected ?? { id: handleNewId(), name: '', type: '', archived: false, options: [], required: false });
    const [ option, setOption ] = useState('');
    const required = ['checkbox','radio','dropdown'];

    const questionOptions = [{name: 'Text Box', id: 'text-box'},{name: 'Text Area', id: 'text-area'},{name: 'Count', id: 'number'},{name: 'Dropdown', id: 'dropdown'},{name: 'Radio', id: 'radio'},{name: 'Checkbox(es)', id: 'checkbox'},{name: 'Icon Rating', id: 'icon-rating'},{name: 'Number Rating', id: 'number-rating'}];
    
    const handleAddQuestion = () => {
        const tempOptions = [ ...question.options ];
        
        if (option) {
            tempOptions.push(option);
        };

        if (selected) {
            dataDispatch({ type: 'survey-update-question', data: { ...question, options: tempOptions } });
        } else {
            dataDispatch({ type: 'survey-new-question', data: { ...question, options: tempOptions } });
        };

        handleCancel();
    };

    const handleCancel = () => {
        setOption('');
        setQuestion({ id: handleNewId(), name: '', type: '', archived: false, options: [], required: false });
        setOpen(false);
        setSelected(null);
    };

    useEffect(() => {
        if (selected) {
            setQuestion(selected);
            setOpen(true);
        };
    },[selected, setQuestion]);

    const handleSelect = (e) => {
        setQuestion(prev => ({...prev, type: e.target.value}));
        setOpen(true);
    };

    useEffect(() => {
        if (!open) {
            handleCancel();
        };
    },[open])

    return (
        <>
        <div className={styles.selectWrap}>
            <select className={styles.addColumnBtn} value={question?.type ?? ''} onChange={handleSelect} disabled={(originalQRSurvey?.data?.survey?.questions?.find(q => q?.id === question?.id))}>
                <option value='' disabled default >Add Form Field</option>
                {questionOptions.map((opt, index) => <option key={opt.id+index} value={opt.id} disabled={(opt.id === 'signature' && questions?.find(q => q.type === 'signature')) ? true : false}>{opt.name}</option>)}
            </select>
        </div>
        <div className={open ? styles.overlay : `${styles.overlay} ${styles.hidden}`}>
            <div className={styles.modal} ref={ref}>
                <div className={styles.modalHeader}>
                    <div>Question Information</div>
                    <span>Fill out necessary fields to complete your form.</span>
                </div>
                <div className={styles.modalBody}>
                    <div className={styles.inputGroup}>
                        <textarea id='column-name-label' className={styles.input} maxLength='500' value={question?.name} onChange={e => setQuestion(prev => ({...prev, name: e.target.value}))} />
                        <label htmlFor='column-name-label' className={question?.name ? styles.labelFilled : styles.label}>Question</label>
                    </div>
                    <QuestionTypes question={question} setQuestion={setQuestion} option={option} setOption={setOption} />
                    {(required.includes(question?.type) && question.options.length < 1) ? <span className={styles.optionWarn} ><i className='fa-solid fa-asterisk fa-xs me-1' /> One option required.</span> : <></>}
                    <div className={styles.saveCancelContainer}>
                        <button className={styles.addColumnBtn} onClick={handleAddQuestion} disabled={!question?.type || !question?.name || (required.includes(question.type) && question.options.length < 1)}>Confirm</button>
                        <button className={styles.addColumnBtn} onClick={handleCancel}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Question;