import React from 'react';
import Tags from '../../Tags';
import Categories from '../../Categories';
import styles from './Menu.module.css';

export default function Menu(props) {
    // ----- STATE INITIALIZATION -----
    const {
        qr_data,
        dataDispatch,
        categoryList,
        setCategoryList,
        plan,
        plan_id
    } = props;
    
    return (
        <>
        <h5 className={styles.header}>Menu</h5>
        <Categories categoryList={categoryList.filter(cat => cat.id !== 'Uncategorized')} setCategoryList={setCategoryList} qr_data={qr_data} dataDispatch={dataDispatch} plan_id={plan_id} plan={plan} />
        <Tags qr_data={qr_data} dataDispatch={dataDispatch} />
        </>
    );
};