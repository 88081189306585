import React, { useRef, useState, useEffect } from 'react';
import QRCodeStyling from 'pp-qr-code';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useNavigate } from 'react-router-dom';
import { useTypeHeaderIcon } from '../../../../../utils/hooks/useTypeHeaderIcon';
import styles from './LinkedQRDisplay.module.css';

export default function LinkedQRDisplay(props) {

    const { QR, setQRtoEdit, setDisplay, optionsDispatch, dataDispatch } = props;

    const qrRef = useRef(null);
    const navigate = useNavigate();

    const options = QR?.data?.qr_style;
    const [ code, setCode ] = useState(new QRCodeStyling(options));
    const { icon } = useTypeHeaderIcon(QR?.data?.qr_type);
    const [ confirm, setConfirm ] = useState(false);

    useEffect(() => {
        if (!code && options) {
            setCode(new QRCodeStyling(options));
        } else if (code && options) {
            code.update(options);
            code.append(qrRef.current);
        };
    },[code, options]);

    useEffect(() => {
        if (confirm) {
            const timeout = setTimeout(() => {
                setConfirm(false);
            },2000);

            return () => clearTimeout(timeout);
        };
    },[confirm]);

    const handleUnlink = () => {
        setQRtoEdit(QR);
        setDisplay('edit-qr');
        optionsDispatch({type: 'edit', options: QR.data.qr_style});
        dataDispatch({type: 'edit', data: QR.data});
        navigate('/collection/edit');
    };

    return (
        <div className={styles.card} id={QR.id}>
            <OverlayTrigger 
                placement="top"
                overlay={
                    <Tooltip className={styles.tip}>
                        <div>Name: <span>{QR.data.nickname}</span></div>
                        <div>Type: <span>{QR.data.qr_type.charAt(0).toUpperCase() + QR.data.qr_type.slice(1)} <i className={icon} /></span></div>
                    </Tooltip>}
            >
                <div className={styles.name}>{QR.data.nickname}</div>
            </OverlayTrigger>
            <div className={styles.qr} ref={qrRef}>{!code ? <i className='fa-duotone fa-spinner fa-spin' style={{fontSize: '2rem'}} /> : <></>}</div>
            <div className={styles.btns}>
                <button className={confirm ? `${styles.unlinkBtn} ${styles.hide}` : styles.unlinkBtn} onClick={() => setConfirm(true)}>Unlink</button>
                <button className={confirm ? `${styles.confirmBtn} ${styles.show}` : styles.confirmBtn} onClick={handleUnlink}>Confirm</button>
            </div>
        </div>
    );
};