import React, { useState, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import QRRef from '../QRRef';
import API from '../../../API';
import RedirectLink from '../QRTypes/RedirectLink';
import Menu from '../QRTypes/Menu';
import PDF from '../QRTypes/PDF';
import Appointment from '../QRTypes/Appointment';
import Location from '../QRTypes/Location';
import Microsite from '../QRTypes/Microsite';
import Tracker from '../QRTypes/Tracker';
import Survey from '../QRTypes/Survey';
import Asset from '../QRTypes/Asset';
import BitSignal from '../QRTypes/BitSignal';
import Style from '../Style';
import TypeSelect from '../TypeSelect';
import Schedule from '../Schedule';
import Tabs from '../Tabs';
import Options from '../Options';
import { useNavigate } from 'react-router-dom';
import styles from './EditQR.module.css';
import LinkQR from '../LinkQR';

export default function EditQR(props) {
    // ----- STATE INITIALIZATION -----
    const {
        path,
        categories,
        dataDispatch,
        formData,
        handleReset,
        id,
        logo,
        optionsDispatch,
        org_id,
        plan_id,
        plan,
        QRtoEdit,
        qr_options,
        qr_data,
        reference,
        setCategories,
        setLogo,
        setSearchReturnArr,
        setStep,
        step,
    } = props;
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { createLiquidQR, updateQR, storeLogo, storeFiles, deleteFile, createNewCategory } = API;

    const [ display, setDisplay ] = useState('shape');
    const [ valid, setValid ] = useState(false);
    const [ qrType, setQrType ] = useState(path === 'edit' ? QRtoEdit?.data?.qr_type : '');
    const [ loading, setLoading ] = useState(false);
    const [ qrFiles, setQrFiles ] = useState([]);
    const [ filesToDisplay, setFilesToDisplay ] = useState(qr_data?.asset?.route?.length > 0 ? qr_data.asset.route.map(file => ({url: file, raw: file})) : []);
    const [ deleteFiles, setDeleteFiles ] = useState([]);
    const [ categoryList, setCategoryList ] = useState([]);
    const [ linking, setLinking ] = useState(false);
    const [ edit, bsEnt ] = useMemo(() => [searchParams.has('edit', 'true'), searchParams.has('ent', 'true')],[searchParams]);
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- ONLOAD AND UPDATE -----
    useEffect(() => {
        if (path === 'new') {
            setStep(1);
            optionsDispatch({ type:'liquid', options: { randKey: id } });
        } else if (path === 'edit' && id?.startsWith('-') && (!QRtoEdit.data?.org_id || QRtoEdit.data?.linked_qr)) {
            setStep(1);
        } else {
            if (edit && bsEnt) return;
            setStep(5);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path, QRtoEdit, id]);
    
    useEffect(() => {
        setCategoryList([...categories]);
        return () => categoryList?.length && setCategoryList([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[categories, setCategoryList]);

    const handleBack = () => {
        setStep(0);
        setLoading(false);
        handleReset();
        setQrFiles([]);
        setDeleteFiles([]);

        if (reference) {
            if (reference === 'bs') {
                try {
                    window.opener.postMessage({ type: 'back', uid: '', qrId: '', error: '', success: true }, process.env.REACT_APP_BS_ORIGIN);
                    window.close();
                } catch (err) {
                    console.error(err);
                };
                
                return;
            };

            navigate(`/${reference}/${id}`);
        } else {
            if (path === 'new') {
                if (qrType) {
                    setQrType('');
                    dataDispatch({ type: 'reset', data:{} });
                    setStep(1);
                    setQrFiles([]);
                    optionsDispatch({ type: 'liquid', options: { randKey: id } });
                } else if (!qrType) {
                    handleReset();
                    setQrFiles([]);
                    navigate('/collection');
                };
            } else navigate('/collection', { state: { qrID: id } });
        };
    };
    // ----- END ONLOAD AND UPDATE -----
    // ----------------------------------------------
    
    // ----- VALIDITY CHECK -----
    // QR data valid to create/update
    useEffect(() => {
        if (qr_data?.nickname?.length < 1) {
            setValid(false);
            return;
        } else {
            switch (qrType) {
                case 'pdf':
                case 'user-manual':
                case 'brochure':
                    setValid(qr_data.pdf?.length > 0 && qr_data.nickname?.length > 0);
                    break;
                case 'menu':
                    setValid(plan_id > 0 && qr_data.menu?.length > 0 && qr_data.nickname?.length > 0);
                    break;
                case 'microsite':
                case 'landing-page':
                    setValid(plan_id > 0 && qr_data.microsite && qr_data.nickname?.length > 0);
                    break;
                case 'location':
                    setValid(plan_id > 0 && qr_data.location?.lat && qr_data.location?.lng && qr_data.nickname?.length > 0);
                    break;
                case 'bitsignal':
                case 'dbc':
                    setValid(qr_data.nickname?.length > 0 && qr_data.bitsignal?.uid?.length > 0 && qr_data?.bitsignal?.authenticated === true);
                    break;
                case 'calendly':
                    setValid(plan_id > 0 && qr_data.redirect_url && qr_data.redirect_url.includes('calendly') && qr_data.nickname?.length > 0);
                    break;
                case 'google-calendar':
                    setValid(plan_id > 0 && qr_data.redirect_url && qr_data.redirect_url.includes('calendar.google') && qr_data.nickname?.length > 0);
                    break;
                case 'assets':
                case 'liquid-label':
                case 'inventory':
                case 'tracker':
                case 'survey':
                case 'review':
                case 'workout-log':
                case 'service-log':
                case 'quiz':
                // case 'bug-report':
                case 'maintenance-tracker':
                case 'vehicle-log':
                case 'container-label':
                    setValid(qr_data.nickname?.length > 0);
                    break;
                case 'google-review':
                case 'appointment':
                case 'music':
                case 'youtube':
                case 'tiktok':
                case 'linkedin':
                case 'link':
                case 'review-link':
                default:
                    setValid(qr_data.redirect_url && qr_data.nickname?.length > 0);
                    break;
            };
        };
    },[qr_data, qrType, plan_id]);
    // ----- END VALIDITY CHECK -----
    // ----------------------------------------------

    // ------ UPDATE FUNCTIONS ------
    const qrFormData = () => (new Promise ((resolve, reject) => {
        const form = new FormData();

        for (const file of qrFiles) {
            form.append('file', file.data, `${file.name}`);
        };

        resolve(form);
    }));

    const handleDeleteQrFiles = async () => {
        if (deleteFiles.length > 0) {
            deleteFiles.forEach(async file => await deleteFile(file).catch(err => console.log(err)));
            setDeleteFiles([]);
        };
    };

    // Updates QR
    const handleEdit = async () => {
        setLoading(true);

        if (setSearchReturnArr) {
            setSearchReturnArr(prev => Array.isArray(prev) ? prev.map(qr => {
                if (qr.id === id) {
                    qr.data.category = qr_data.category;
                };

                return qr;
            }) : prev);
        };

        const date = new Date();
        
        const data = {
            active: true,
            category: qr_data.category ?? null,
            nickname: qr_data.nickname,
            qr_type: qrType,
            qr_style: qr_options,
            org_id: path === 'new' ? org_id : (QRtoEdit.data?.org_id ? QRtoEdit.data.org_id : org_id),
            redirect_url: qr_data.redirect_url,
            schedule: qr_data?.schedule ? qr_data.schedule : null,
            tags: qr_data.tags,
            updated_at: date
        };

        if (path === 'new' && id.startsWith('-') && QRtoEdit.data?.linked_qr) {
            data.linked_qr = QRtoEdit.data.linked_qr;
        };

        if (qr_data?.category && categories?.length !== categoryList?.length) {
            categoryList?.filter(cat => categories.find(c => c.id === cat.id) ? false : true).forEach(async (cat) => {
                if (cat?.id !== 'Uncategorized') {
                    setCategories(prev => ([ ...prev, cat ]));
                    await createNewCategory({ category: cat });
                };
            });
        };

        if (path === 'new' || (!QRtoEdit?.data?.org_id && !QRtoEdit?.data?.created_at && id.startsWith('-'))) {
            data.created_at = date;
        };

        // Remove image cache query if it exists
        if (logo && path === 'new') {
            data.qr_style.image = `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_logos/${id}/logo.png`;
        } else if (data.qr_style.image) {
            data.qr_style.image = data.qr_style.image.split('?')[0];
        };

        // Set mobile created QRs to 300x300
        if (path === 'new') {
            if (qr_options.height !== 300) {
                data.qr_style.height = 300;
            };
            if (qr_options.width !== 300) {
                data.qr_style.width = 300;
            };
        } else {
            if (QRtoEdit.data.qr_style.height !== 300) {
                data.qr_style.height = 300;
            };
            if (QRtoEdit.data.qr_style.width !== 300) {
                data.qr_style.width = 300;
            };
        };
        
        // Handles menu and pdf changes
        switch (qrType) {
            case 'link':
            case 'youtube':
            case 'linkedin':
            case 'tiktok':
            case 'google-review':
            case 'review-link':
                data.redirect_url = qr_data.redirect_url;
                if (qr_data?.alternate_redirect_url?.length > 0) data.alternate_redirect_url = qr_data.alternate_redirect_url;
                break;
            case 'review':
            case 'survey':
            // case 'bug-report':
                if (qr_data?.survey) data.survey = qr_data.survey;
                break;
            case 'tracker':
            case 'workout-log':
            case 'service-log':
            case 'vehicle-log':
            case 'maintenance-tracker':
                if (qr_data?.tracker) data.tracker = qr_data.tracker;
                break;
            case 'microsite':
            case 'landing-page':
                if (qr_data?.microsite) data.microsite = qr_data.microsite;
                break;
            case 'pdf':
            case 'user-manual':
                data.pdf = qr_data.pdf;
                if (qrFiles?.length > 0) await storeFiles(id, await qrFormData()).then(() => setQrFiles([]));
                if (deleteFiles?.length > 0) handleDeleteQrFiles();
                break;
            case 'menu':
                data.menu = qr_data.menu;
                if (qrFiles?.length > 0) await storeFiles(id, await qrFormData()).then(() => setQrFiles([]));
                if (deleteFiles?.length > 0) handleDeleteQrFiles();
                break;
            case 'location':
                if (qr_data?.location) data.location = qr_data.location;
                break;
            case 'bitsignal':
            case 'dbc':
                data.bitsignal = qr_data.bitsignal;
                break;
            case 'assets':
            case 'container-label':
            case 'liquid-label':
            case 'inventory':
                data.asset = qr_data.asset;
            
                if (deleteFiles?.length > 0) {
                    data.asset.route = data?.asset?.route?.filter(route => !deleteFiles.includes(route));
                    handleDeleteQrFiles();
                };
    
                const routes = qrFiles.map((file) => `${id}/${`${file.name}`}.${file.data.type.split('/')[1]}`);
                
                if (qrFiles?.length > 0) {
                    await storeFiles(id, await qrFormData()).then(() => setQrFiles([]));
                };
    
                if (qr_data?.asset?.route?.length) {
                    routes.unshift(...qr_data.asset.route);
                };
    
                data.asset = { ...qr_data.asset, route: routes };
                break;
            default:
                break;
        };

        // Saves Update
        if (path === 'new') {
            try {
                if (logo) {
                    await storeLogo(id, formData);
                };

                await createLiquidQR(id, data);
                handleReset();
                navigate('/collection', { state: { qrID: id } });
            } catch (err) {
                console.error(err);
                setLoading(false);
            };
        } else {
            try {
                if (formData) {
                    data.qr_style.image = `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_logos/${id}/logo.png`;
                    await storeLogo(id, formData);
                };
                
                await updateQR(id, data);
                setLoading(false);
                handleReset();

                if (reference === 'bs') {
                    window.opener.postMessage({ type: 'edit', success: true, qr_style: data.qr_style }, process.env.REACT_APP_BS_ORIGIN);
                    window.close();
                    return;
                } else {
                    navigate('/collection', { state: { qrID: id }});
                };
            } catch (err) {
                if (reference === 'bs') {
                    window.opener.postMessage({ type: 'edit', success: false, qr_style: {} }, process.env.REACT_APP_BS_ORIGIN);
                    window.close();
                };
                console.log(err);
            };
        };
    };
    // ------ UPDATE FUNCTIONS ------
    // ----------------------------------------------

    // ----- RETURN CONDITIONAL -----
    const handleQRTypeReturn = () => {
        switch (qrType) {
            case 'menu':
                return <Menu qr_data={qr_data} dataDispatch={dataDispatch} categoryList={categoryList} setCategoryList={setCategoryList} plan={plan} plan_id={plan_id} />
            case 'pdf':
            case 'brochure':
            case 'user-manual':
                return <PDF qrType={qrType} qr_data={qr_data} dataDispatch={dataDispatch} categoryList={categoryList} setCategoryList={setCategoryList} plan={plan} plan_id={plan_id} />
            case 'location':
                return <Location qr_data={qr_data} dataDispatch={dataDispatch} categoryList={categoryList} setCategoryList={setCategoryList} plan={plan} plan_id={plan_id} />
            case 'microsite':
            case 'landing-page':
                return <Microsite qrType={qrType} qr_data={qr_data} dataDispatch={dataDispatch} categoryList={categoryList} setCategoryList={setCategoryList} plan={plan} plan_id={plan_id} />
            case 'appointment':
                return <Appointment qr_data={qr_data} dataDispatch={dataDispatch} categoryList={categoryList} setCategoryList={setCategoryList} plan={plan} plan_id={plan_id} />
            case 'tracker':
            case 'workout-log':
            case 'service-log':
            case 'maintenance-tracker':
            case 'vehicle-log':
                return <Tracker qrType={qrType} qr_data={qr_data} dataDispatch={dataDispatch} categoryList={categoryList} setCategoryList={setCategoryList} plan={plan} plan_id={plan_id} />
            case 'survey':
            // case 'bug-report':
            case 'review':
                return <Survey qrType={qrType} qr_data={qr_data} dataDispatch={dataDispatch} categoryList={categoryList} setCategoryList={setCategoryList} plan={plan} plan_id={plan_id} />
            case 'liquid-label':
            case 'assets':
            case 'inventory':
            case 'container-label':
                return <Asset qrType={qrType} qr_data={qr_data} dataDispatch={dataDispatch} categoryList={categoryList} setCategoryList={setCategoryList} plan={plan} plan_id={plan_id} />
            case 'link':
            case 'linkedin':
            case 'tiktok':
            case 'youtube':
            case 'google-review':
            case 'review-link':
                return <RedirectLink qrType={qrType} qr_data={qr_data} dataDispatch={dataDispatch} categoryList={categoryList} setCategoryList={setCategoryList} plan={plan} plan_id={plan_id} />
            case 'bitsignal':
            case 'dbc':
                return <BitSignal qr_data={qr_data} dataDispatch={dataDispatch} categoryList={categoryList} setCategoryList={setCategoryList} plan={plan} plan_id={plan_id} />
            default:
                return <TypeSelect qrType={qrType} setStep={setStep} setQrType={setQrType} plan_id={plan_id} setLinking={setLinking} />
        };
    };
    // ----- END RETURN CONDITIONAL -----
    // ----------------------------------------------

    const handleMainReturn = () => {
        switch (step) {
            case 1:
                return <TypeSelect dataDispatch={dataDispatch} setStep={setStep} qrType={qrType} setQrType={setQrType} plan_id={plan_id} setLinking={setLinking} />;
            case 2:
                return (<>{handleQRTypeReturn()}</>);
            case 3: 
                return <Style qr_options={qr_options} optionsDispatch={optionsDispatch} display={display} setDisplay={setDisplay} logo={logo} setLogo={setLogo} />
            case 4:
                return <Schedule qr_data={qr_data} dataDispatch={dataDispatch} id={id} qrType={qrType} setQrFiles={setQrFiles}/>
            case 5:
                return <Options qrType={qrType} qr_data={qr_data} dataDispatch={dataDispatch} id={id} qrFiles={qrFiles} setQrFiles={setQrFiles} setDeleteFiles={setDeleteFiles} filesToDisplay={filesToDisplay} setFilesToDisplay={setFilesToDisplay} />
            default:
                return (<>{handleQRTypeReturn()}</>);
        };
    };

    return (
        <div className={styles.container}>
            {step > 1 ? 
                <Tabs qrType={qrType} step={step} setStep={setStep} />
            :
                <></>
            }
            {linking ?
                <LinkQR
                    QR={QRtoEdit || id} 
                    qr_options={qr_options}
                    optionsDispatch={optionsDispatch}
                    qr_data={qr_data}
                    dataDispatch={dataDispatch}
                    categories={categories}
                    org_id={org_id}
                    setLogo={setLogo}
                    formData={formData}
                    handleReset={handleReset}
                    setLinking={setLinking}
                />
                :
                <div className={styles.containerInner}>
                    <div className={step === 3 ? styles.containerInner1 : styles.containerSteps}>
                        <button className={styles.backBtn} onClick={handleBack} style={window.location.pathname.includes('setup') ? {display: 'none'} : null}><i className='fas fa-arrow-left me-2' style={{pointerEvents: 'none'}} />Back</button>
                        {path === 'edit' ? <p className={styles.changeBtn} style={window.location.pathname.includes('setup') ? {left: '1rem'} : null} onClick={() => setStep(1)}>Change QR Type</p> : <></>}
                        <button className={styles.createBtn} disabled={!valid || loading} onClick={handleEdit}>{loading ? <i className='fa-duotone fa-spinner fa-spin'></i> : 'Finish'}</button>
                        {handleMainReturn()}
                    </div>
                    {step === 3 ?
                        <div className={styles.containerInner2}>
                            <QRRef loading={loading} qr_options={window.innerWidth < 768 ? { ...qr_options, height: 225, width: 225 } : { ...qr_options, height: 300, width: 300 }} />
                        </div>
                    :
                        <></>
                    }
                </div>
            }
        </div>
    );
};
